* {
    box-sizing: border-box;
}

footer {
    /* margin-top: 1em; */
    margin-bottom: 1em;
    text-align: center;
    margin-bottom: 0;
    /* margin-top: 6.5em; */
}

.footer-background-image {
    min-height: 25em;
    background-image: radial-gradient(ellipse at center, rgba(2, 7, 13, 0.55) 0%, rgba(9, 11, 12, 1) 100%), url("../images/footer-image-orig.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    /* padding-top: 9.5em; */
    padding-top: 4em;
    box-shadow: 12em 13em 8em 0em #101316 inset;
}

.footer--short .footer-background-image {
    min-height: 15em;
    padding-top: 2em;
}

.footer-logo,
.footer-social-links {
    width: 100%;
}

.footer-logo.menu-item {
    margin-left: 0;
}

.footer-logo {
    position: relative;
    /* top: 8em; */
    right: 0;
}

.footer-logo img {
    width: 25%;
    max-width: 20em;
}

.footer-nav,
.footer-logo img {
    padding-bottom: 1em;
}

footer a {
    color: white;
}

footer ul li {
    list-style: disc;
    color: white;
    padding-right: 0.25em;
}

footer .menu-item {
    margin-left: 1.5em;
    margin-right: 1em;
}

footer .menu-item a {
    padding-left: 0.5em;
}

.sitem-row-1,
.sitem-row-2,
.sitem-row-3,
.basic-links-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-bottom: 0.5em;
}

.sitem-row-1 {
    margin-top: 1.5em;
}

.sitem-row-2 {
    padding-top: 1em;
}

.footer-social-links {
    margin-top: 1em;
}

footer ul li:first-of-type {
    list-style: none;
}

.sitem-row-3 {
    padding-top: 2em;
    font-size: 0.9em;
    width: 100%;
    padding-bottom: 3em;
}

footer .basic-links-row {
    padding-top: 3.5em;
    padding-bottom: 0.5em;
}

.footer-menu {
    font-weight: bold;
    font-size: 0.85em;
    text-shadow: 0px 0px 20px rgba(2, 7, 13, 0.9);
}

.footer-address {
    bottom: 0;
    left: 0;
    background-color: #232323;
    width: 100%;
    padding: 1em;
}

.footer-address,
.footer-address-top p,
.footer-address-bottom p {
    font-size: 0.9em;
    font-weight: bolder;
}

.footer-007-logo {
  height: 1em;
}

@media screen and (max-width: 890px) {

    .sitem-row-1,
    .sitem-row-2 {
        flex-flow: column wrap;
    }

    .sitem-row-2 {
        padding-top: 0;
    }

    .sitem-row-3 {
        padding-top: 0;
    }

    .sitem-row-3 li,
    .basic-links-row li {
        display: block;
        width: 100%;
    }

    footer ul li {
        list-style: none;
        font-size: 1.75em;
        padding: 1em;
    }

    .basic-links-row li {
        font-size: 1.25em;
    }

    footer .menu-item {
        margin-left: 0;
        margin-right: 0;
    }

    .footer-address {
        position: unset;
    }

    .footer-logo img {
        width: 75%;
    }

    .footer-social-links {
        margin-top: 0;
    }

    .footer-address,
    .footer-address-top p,
    .footer-address-bottom p {
        font-weight: bolder;
        font-size: 1.1em;
        line-height: 1.4em;
    }

    .footer-address {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }
}
