#calendar td {
  border-style: none;
  text-align: center;
}

.fc-unthemed td.fc-today {
  background-color: transparent;
}

.fc-today .fc-day-number {
  border-style: solid;
  border-color: white;
  border-width: 2px;
}

.fc-header-toolbar {
  background-color: hsla(0, 27%, 30%, 0.6);
  padding: 0.5em;
  font-family: "GoboldExtra1", "Arial", "Helvetica", "sans-serif";
  font-size: 0.8em;
}

.fc-button-primary,
.fc-button-primary:hover,
.fc-button-primary:active,
.fc-button-primary:focus {
  background-color: transparent;
  border-style: none;
}

.fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
  float: none;
  background-color:#434343;
  fill: rgb(46, 46, 46);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  margin: auto;
  line-height: 100%;
  align-items: center;
  justify-content: center;
  font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
  font-size: 1.5em;
  border-style: dotted;
}

.fc-day-top[data-availability] .fc-day-number {
  cursor: pointer;
}

.fc-day-top[data-availability="green"] .fc-day-number:hover,
.fc-day-top[data-availability="amber"] .fc-day-number:hover {
  border: solid white;
  border-style: solid;
}

.fc-other-month {
  visibility: hidden;
}

.fc-ltr .fc-dayGrid-view .fc-day-top[data-availability="green"] .fc-day-number,
.fc-day-top[data-availability="green"] .fc-day-number,
.green {
  background-color: #016a29;
}

.fc-ltr .fc-dayGrid-view .fc-day-top[data-availability="amber"] .fc-day-number,
.fc-day-top[data-availability="amber"] .fc-day-number,
.amber {
  background-color: hsl(34, 100%, 50%);
}

.fc-ltr .fc-dayGrid-view .fc-day-top[data-availability="red"] .fc-day-number,
.fc-day-top[data-availability="red"] .fc-day-number {
  background-color: rgb(53, 13, 14);
  fill: rgb(75, 47, 47);
}

.start-time-option .para-bold,
.select-a-time-message > span:nth-child(1) {
  text-transform: uppercase;
  line-height: 1.25;
  font-size: 0.8em;
  font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
  padding: 0.25em;
}

.slots-list__key {
  column-gap: 1rem;
  font-size: 0.8rem;
  margin-top: 1rem;
  justify-content: space-around;
  max-width: 100%;
  width: 600px;
  margin-inline: auto;
}

.slots-list-key__item {
  display: inline-flex;
  align-items: center;
  gap: 0.5em;
  font-weight: 700;
}

.slots-list__key dt {
  height: 1.2em;
  width: 1.2em;
  flex: 0 0 1.2em;
}

.time-slot-wrapper {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
}

.slot-time-inner {
  padding: 0.25rem 0;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.85rem;
  line-height: 0.95;
}

.slot-time-time {
  font-size: 1.75rem;
  font-weight: 700;
}

@media screen and (min-width: 420px) {
  .slot-time-inner {
    padding: 0.5rem;
    gap: 0.75rem;
  }
  .slot-time-time {
    font-size: 2.1rem;
  }
}

.slot-time-price {
  display: inline-flex;
  margin-left: auto;
  gap: 0.1rem;
  align-items: flex-end;
}

.slot-time-price > * {
  display: inline-block;
}

.slot-time-split {
  width: max-content;
}

.slot-time-price--unit-cost {
  line-height: 0.75;
}

.slot-time-split > *:not(:first-child) {
  font-size: 1.39rem;
}

@media screen and (max-width: 890px) {
  .slot-time-split > *:not(:first-child) {
    font-size: 1.25rem;
  }
}

.slot-time-price--state-message {
  text-align: left;
  margin-left: 0.2rem;
}

.start-time-option .para-bold {
  margin: 0.5em;
}

.select-a-time-message > span:nth-child(2) {
  font-size: 0.75em;
  font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
}

.date-details .select-a-time-message .select-time-heading {
  font-size: 28px;
  margin: 0;
  display: inline-block;
  font-family: "GoboldExtra1", "Arial", "Helvetica", "sans-serif";
  line-height: 1.4;
  letter-spacing: 0.2em;
  width: 100%;
}

@media screen and (max-width: 890px) {
  .fc-row .fc-day-number {
    padding: 5px;
  }
}

#calendar td.fc-day-top {
  vertical-align: middle !important;
  height: 70px;
}

@media screen and (max-width: 890px) {
  #calendar td.fc-day-top {
    vertical-align: middle !important;
    height: 40px;
  }

  #calendar .fc-dayGrid-view .fc-body .fc-row {
    min-height: 2em;
  }

  #calendar .fc-day-header.fc-widget-header {
    padding: 0.2em 0;
  }
}

#calendar th {
  border-style: solid;
  border-width: 0 0 2px 0;
}

#calendar .fc-day-header {
  padding: 1em;
  font-size: 1.5em;
  font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
}

#calendar .fc-head {
  border-style: dotted;
  border-width: 0 0 2px 0;
  border-color: white;
}

#calendar .fc-scroller {
  overflow-y: hidden !important;
}

.fc-scroller.fc-day-grid-container {
  border-bottom: white dotted;
  border-width: 0 0 2px 0;
  margin-bottom: 2em;
}

#calendar td.fc-day-top.selected .fc-day-number {
  border-style: solid;
  border-color: yellow;
  border-width: 2px;
}

.select-date-key span {
  text-align: left;
}

.select-date-key .key-text {
  text-align: center;
}

@media screen and (max-width: 400px) {
  .booking-key.select-date-key span:not(.key-text) {
    margin: auto auto auto 15%;
  }
}

.fc-day-number {
  color: #999999;
}

.not-available {
  color: #999999;
}

[data-availability="green"] .fc-day-number {
  color: rgb(233, 233, 233);
} 
