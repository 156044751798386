.text-image-block--blended-side-by-side--lr,
.text-image-block--blended-side-by-side--rl {
    @extend .text-image-block--blended-side-by-side;
}

.text-image-block--blended__stacked-img-wrapper--lr,
.text-image-block--blended__stacked-img-wrapper--rl {
    @extend .text-image-block--blended__stacked-img-wrapper;
}

.text-image-block__text-wrapper--lr,
.text-image-block__text-wrapper--rl {
    @extend .text-image-block__text-wrapper;
}

.text-image-block__text-wrapper-inner--lr,
.text-image-block__text-wrapper-inner--rl {
    @extend .text-image-block__text-wrapper-inner;
}

section.landing-page section.overlay.text-image-block--blended-side-by-side,
.text-image-block--blended-side-by-side {
    z-index: 1;
    box-shadow: 4em 0em 2em 2em rgb(9, 11, 12) inset;
}

.text-image-block--blended-side-by-side .heading {
    margin-bottom: 1em;
}

.text-image-block--blended__stacked-img-wrapper {
    position: relative;
    box-shadow: 0em 0em 4em 5em rgb(9, 11, 12) inset;
}

.text-image-block--blended__stacked-img-wrapper.overlay:before{
    background-color: rgba(9, 11, 12, 7%);
    @media screen and (min-width: 577px) and (max-width: 1024px) {
        background-color: rgba(9, 11, 12, 50%);
    }
}

.text-image-block--blended__stacked-img,
.text-image-block--blended__stacked-img-alt  {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 22rem;
    position: relative;
    z-index: -1;
}

@media screen and (min-width: 577px) {
    .text-image-block--blended__stacked-img-wrapper {
        position: absolute;
        height: 100%;
        right: 0;
        width: 75%;
        top: 0;
    }

    .text-image-block--blended__stacked-img-wrapper--rl {
        left: 0;
        right: unset;
    }

    .text-image-block--blended__stacked-img {
        max-height: unset;
        height: 100%;
      }

    .text-image-block--blended__stacked-img-alt {
        max-height: unset;
        max-width: 100%;
      }
}

/*
@media screen and (min-width: 577px) and (max-width: 890px) {
    .the-experience.text-image-block--blended__stacked-img-wrapper {
        position: relative;
        height: auto;
        right: unset;
        width: 100%;
    }
} */

@media screen and (min-width: 1024px) {
    .text-image-block--blended__stacked-img-wrapper {
        width: 55%;
    }
}

.text-image-block__text-wrapper {
    position: relative;
    z-index: 2;
    @media screen and (min-width: 577px) {
        width: 80%;
        padding-right: 2rem;
    }
    @media screen and (min-width: 890px) {
        width: 65%;
        padding-right: 0;
    }
    @media screen and (min-width: 1024px) {
        width: 50%;
    }
}

.text-image-block__text-wrapper--rl {
    @media screen and (min-width: 577px) {
        padding: 0;
        margin-left: auto;
    }
}

.text-image-block__text-wrapper-inner {
    width: 450px;
    max-width: 100%;
    margin-left: auto;
    @media screen and (max-width: 576px){
        margin-right: auto;
    }
    
    @media screen and (min-width: 890px) {
        width: 520px;
    }
}
