ul ul {
  display: none;
}

header {
  z-index: 1000;
  position: fixed;
  top: 0px;
  width: 100%;
}

.headwrap {
  width: 100%;
  padding: 0.6em 0;
  background-color: hsla(210, 16%, 7%, 70%);
}

.headwrap * {
  vertical-align: middle;
}

#nav-logo {
  width: 8em;
  height: 2em;
}

.social-icon {
  width: 1.75em;
  height: 1.75em;
  margin: 0.25em;
}

svg#nav-logo:hover {
  fill: #dd1515;
}

svg.social-icon:hover * {
  fill: #dd1515;
}

svg.social-icon.email:hover * {
  fill: transparent;
}

svg.social-icon.email:hover .evst0 {
  fill: #dd1515;
}

svg.social-icon.email:hover .evst1 {
  stroke: #dd1515;
}

.header-social-links {
  display: inline;
  padding: 2em;
  /* margin-left: 2em; */
}

.sign-in-wrapper {
  float: right;
  /* margin-right: 2em; */
}

.sign-in-wrap-mobile {
  position: absolute;
  top: 0;
  right: 0;
  background: radial-gradient(
    ellipse at center,
    hsl(210, 16%, 7%) 0%,
    hsla(210, 18%, 7%, 0.6) 30%,
    hsla(210, 18%, 7%, 0) 90%,
    hsla(210, 18%, 7%, 0) 100%
  );
  opacity: 0.8;
  padding: 0.7em 2em;
}

a.sign-in,
a.sign-in-mobile {
  color: rgb(233, 233, 233);
  font-size: 18px;
  line-height: 1.4em;
}

/* a.sign-in svg, a.sign-in-mobile svg {
    fill: rgb(233, 233, 233);
    width: 1.5rem;
    z-index: 1;
    position: relative;
} */

.arrow-icon img {
  fill: rgb(233, 233, 233);
  width: 1.5rem;
  z-index: 1;
  position: relative;
  vertical-align: middle;
}

nav {
  padding-top: 1.5rem;
}

.main-nav {
  width: 50%;
  margin-bottom: 2em;
  padding-left: 2em;
  padding-right: 2em;
}

.main-navigation {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.main-navigation li {
  list-style: none;
  font-weight: bold;
  display: inline-block;
  width: calc((100% - 5px) / 3);
  text-align: center;
}

#main-menu-navigation-left {
  float: left;
  padding-right: 3em;
}

#main-menu-navigation-left li:last-child {
  padding-right: 2em;
}

#main-menu-navigation-right {
  float: right;
  padding-left: 3em;
}

#main-menu-navigation-right li:first-child {
  padding-left: 2em;
}

.main-navigation a,
.main-ul li {
  color: white;
}

a:hover,
footer a:hover {
  color: #dd1515;
  text-decoration: none;
  cursor: pointer;
}

.main-menu-logo {
  position: absolute;
  top: 0;
  background-color: white;
  padding-top: 4.25em;
  padding-right: 0.75em;
  padding-left: 0.75em;
  padding-bottom: 0.75em;
  margin-top: 0;
  left: calc(50% - 4.75em);
  width: 9.5em;
  /* left: calc(50% - 4em);
    width: 8em; */
  text-align: center;
}

.main-menu-logo img {
  width: 8em;
}

.menu-item {
  z-index: 100;
}

.main-menu-item a,
.footer-menu {
  font-weight: bold;
  font-size: 0.85em;
}

@media screen and (min-width: 890px) {
  .burger-toggle,
  .burger-icon,
  .main-nav-hidden-city-logo,
  .main-nav-hidden-city-logo-mobile,
  .hunt-head-mobile {
    display: none;
  }

  body[data-sticky] .headwrap,
  body[data-sticky] .sign-in-wrap-mobile {
    display: none;
    opacity: 0;
  }

  body[data-sticky] nav {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 1.25em 2em;
  }

  .main-nav {
    height: 56px;
  }

  body[data-sticky] .main-menu-logo {
    padding-top: 20px;
    padding-bottom: 1em;
    height: 56px;
  }

  #header-social-links-toggle {
    display: none;
  }

  .header-social-links-wrapper {
    display: block !important;
  }

  #main-menu-navigation-left li {
    padding-right: 0.75em;
  }

  #main-menu-navigation-right li {
    padding-left: 0.75em;
  }
}

header .mobile-only {
  display: none;
}

@media screen and (max-width: 890px) {
  header .mobile-only {
    display: block;
  }

  .main-nav-hidden-city-logo-mobile {
    display: none;
  }

  header .menu-item {
    opacity: 0;
  }

  .main-menu-logo {
    display: none;
  }

  /* .sign-in,  */
  .sign-in-wrap-mobile {
    display: none;
    opacity: 0;
    background: transparent;
  }

  #burger-toggle .login-text {
    background: none;
    display: inline;
  }

  .main-nav-hidden-city-logo-mobile {
    position: absolute;
    top: 7em;
    left: 1em;
  }

  .main-nav-hidden-city-logo-mobile img {
    width: 50%;
  }

  .main-nav-hidden-city-logo img {
    height: 2.3em;
    margin: 0 0 0 0.5em;
    position: relative;
    z-index: 2;
    filter: invert(1);
  }

  .hunt-head {
    display: block;
  }

  .menu nav {
    position: relative;
    top: 2em;
  }

  .menu {
    line-height: 3em;
    width: 100%;
    display: block;
  }

  .main-menu-logo {
    padding-top: 4em;
    left: 0;
    background-color: transparent;
  }

  .main-menu-logo img {
    filter: none;
  }

  .main-navigation {
    flex-flow: column nowrap;
  }

  .menu {
    position: absolute;
    display: none;
    flex-direction: column;
    opacity: 0;
    width: 100%;
    height: 115vh;
    padding: 50px;
    background: hsl(210, 16%, 7%);
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */
    transform-origin: 0% 0%;
  }

  #main-menu-navigation-left {
    padding-top: 3.5em;
  }

  #main-menu-navigation-left,
  #main-menu-navigation-right {
    padding: 0;
    margin-bottom: 0;
    clear: both;
    width: 100%;
  }

  #main-menu-navigation-right li:first-child,
  #main-menu-navigation-left li:last-child {
    padding-right: 0;
    padding-left: 0;
  }

  .main-nav li {
    padding: 7.5px 0;
    font-size: 22px;
    text-align: center;
    width: 100%;
  }

  #burger-toggle {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    /* right: 50px;     */
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
  }

  #burger-toggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: 7px;
    /* left: -5px;        */
    right: 5px;
    opacity: 0;
    z-index: 8;
    -webkit-touch-callout: none;
  }

  /* burger */
  #burger-toggle .burger-icon {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 5px;
    top: 19px;
    z-index: 5;
  }

  #burger-toggle .burger-icon span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: rgb(233, 233, 233);
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.15s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #burger-toggle .burger-icon span:first-child {
    transform-origin: 0% 0%;
  }

  #burger-toggle .burger-icon span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* Transform burger into crossmark */
  .mobile-menu-open #burger-toggle .burger-icon span {
    opacity: 1;
    transform: rotate(45deg) translate(-1px, -2px);
    /* background: #101316; */
  }

  .mobile-menu-open #burger-toggle .burger-icon span:nth-last-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  .mobile-menu-open #burger-toggle .burger-icon span:nth-last-child(1) {
    opacity: 1;
    transform: rotate(-45deg) translate(-2px, -2px);
  }

  .mobile-menu-open #burger-toggle .hunt-head .sign-in a {
    position: relative;
    z-index: 2;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    top: 40em;
  }

  #header-social-links-toggle {
    position: absolute;
    z-index: 20;
    width: 50px;
    height: 50px;
    top: 5px;
    right: 50px;
  }

  .header-social-links {
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
  }

  .header-social-links-wrapper {
    width: 100%;
    text-align: right;
    padding: 0.5em;
    display: none;
    position: relative;
    z-index: 5;
  }
}


@media screen and (max-width: 890px) {
  
  /* flex mobile menu  */
  .menu {
    height: 110vh;
    padding: 60px 50px 25vh;
  }
  #head {
    z-index: 100001;
  }
  .menu nav {
    top: 0;
  }
  .main-navigation {
    height: 100%;
    justify-content: space-around;
  }
  #main-menu-navigation-left {
    flex: 4;
  }
  #main-menu-navigation-right {
    flex: 5;
  }
  .main-nav li {
    padding: 0;
  }
}

@media screen and (max-width: 890px) and (max-height: 700px) {
  .menu {
    height: 100vh;
    padding: 60px 50px 70px;
  }
}


/* For pages without header navigathin - i.e. MGAKITH  */
.simplified-header{
  .burger-icon {
    display: none;
  }
  #header-social-links-toggle {
    right: 5px;
  } 
}

// Alert banner

.alert-banner {
  z-index: 5;
  width: 100%;
  position: fixed;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  visibility: hidden;

  * {
    // Links inside banner shouldn't be red!
    color: white !important;
  }

  a {
  font-weight: bold;
  text-decoration: underline;
  }

  p {
    margin: 0; 
    padding: 1em;
    padding-right: 1em;
    padding-left: 1em;
    padding-right: 2.5em;
    padding-left: 2em;
  }

}

@media(max-width: 890px){

  .alert-banner {
    justify-content: left;

    p {
      font-size: 1rem;
    }

  }

}

.button-close {
  background-image: url(../images/icons/close-banner.svg);
  z-index: 5;
  color: white;
  width: 1em;
  height: 1em;
  background-size: contain;
  position: absolute;
  right: 1em;
}
