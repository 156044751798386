.ringed-bullet-list {
    li {
        margin-bottom: 1em;
        padding: 0;
        padding-left: 1em;
        line-height: 1.5;
        display: flex;
        p {
            line-height: inherit;
            font-size: inherit;
            letter-spacing: inherit;
            margin: 0;
        }
    }
    
    li::before {
        content: url('../images/icons/bullet-circle--grey-small.svg');
        left: -1em;
        position: relative;
        top: 0.1em;
    }
}

.ringed-bullet-list--grey {
    @extend .ringed-bullet-list;
    li::before {
        top: 0.2em;
    }
}

.ringed-bullet-list--red {
    @extend .ringed-bullet-list;
    li::before {
        content: url('../images/icons/bullet-circle--red-big.svg');
    }
}
