@import url(fonts.css);
@import url(reset.css);
@import url(tables.css);

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  padding: 0px;
  margin: 0px;
  min-width: 300px;
  background: #101316;
  color: rgb(233, 233, 233);
}

body,
section,
.actions-table,
h1,
h2,
h3,
h4,
h5,
.step,
body section,
.horizontal-margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.background-image {
  background-size: cover;
}

button,
.cursor-pointer {
  cursor: pointer;
}

/* Generic styles  */

.red {
  color: #dd1515;
}

.grey-out {
  color: #434343;
  border-color: #434343;
}

.red-border {
  border: solid thin #dd1515;
}

.white-base {
  background-color: white;
  color: black;
}

.black-base {
  background-color: rgba(9, 11, 12, 1);
}

.white-text {
  color: #e9e9e9;
}

section.hidden * {
  display: none;
}

.pointer-none {
  pointer-events: none;
}

.text-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.inline-block {
  display: inline-block;
}

.flex,
.flex-column,
.flex-wrap {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .column-mob {
    flex-direction: column;
  }
}

@media (max-width: 900px) {
  .column-tablet {
    flex-direction: column;
  }
}

.justify-end {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-space-between {
  -ms-flex-pack: space-between;
  justify-content: space-between;
}

.align-center {
  -ms-flex-align: center;
  align-items: center;
}

.flex-1 {
  flex: 1 1;
}

.flex-2 {
  flex: 2 2;
}

.flex-0 {
  flex: 0 0;
}

.full-width {
  width: 100vw;
}

@media (max-width: 890px) and (orientation: landscape) {
  .full-height {
    height: auto;
    min-height: 100vh;
  }
}

.width-32 {
  max-width: 32rem;
  margin-left: auto;
  margin-right: auto;
}

.width-43 {
  display: block;
  max-width: 43rem;
  margin: auto;
}

.width-55__pad-bottom {
  max-width: 55em;
  padding-bottom: 3em;
}

.width-55r {
  max-width: 55rem;
}

.width-68 {
  max-width: 68rem;
  margin-left: auto;
  margin-right: auto;
}

.width-80 {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.width-max-c {
  width: max-content;
}

.width-min-c {
  width: min-content;
}

.happy-margin {
  margin: 1rem;
}

.vertical-margin-none {
  margin-top: 0;
  margin-bottom: 0;
}

.happy-padding {
  padding: 1rem;
}

.vertical-happy-padding {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.happy-padding-bottom {
  padding-bottom: 1rem;
}

.horizontal-happy-padding {
  padding-left: 1rem;
  padding-right: 1rem;
}

.horizontal-auto {
  margin-right: auto;
  margin-left: auto;
}

.tiny-vertical-padding {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.tiny-horizontal-padding {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.vertical-padding-3 {
  padding-top: 3em;
  padding-bottom: 3em;
}

@media screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
  .tight-text-mob {
    letter-spacing: -0.04em;
  }

  .container.clear-horizontal-padding-mob,
  .clear-horizontal-padding-mob {
    padding-left: 0;
    padding-right: 0;
  }

  .container.tiny-horizontal-padding {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
}
@media screen and (min-width: 600px) {
  .hide-on-non-mob {
    display: none;
  }
}

.privacy-policy-section {
  margin: 2em;
}

.inset-shadow {
  box-shadow: 2em -1em 5em 5em #101316 inset;
}

.rounded {
  border-radius: 5px;
}

.generic-background-wrapper {
  position: relative;
  padding-bottom: 2em;
  background-size: cover;
  background-position: center;
  margin-bottom: 0;
}

video {
  max-width: 100%;
  display: block;
}

video[data-src="false"] {
  opacity: 0;
}

video[data-loaded="true"] {
  transition: all 1000ms;
  opacity: 1;
}

/* Only show icons after js and stylesheet ready (see inline styles) */
video[data-loaded="true"] ~ div .trailer-video-controls,
video[data-loaded="true"] ~ div .full-video-link-wrap,
video[data-loaded="true"] ~ div .flavour-text {
  display: block;
}

.whitespace_preline,
.whitespace_preline * {
  white-space: pre-line;
}

.whitespace-none {
  white-space: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.not-mobile {
  display: none;
}

@media (min-width: 576px) {
  .mobile-only {
    display: none;
  }

  .not-mobile {
    display: block;
    display: revert;
  }

  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

/* Major sections  */

.transparent-background {
  background-color: transparent !important;
}

body > section,
section.main {
  background-color: rgba(9, 11, 12, 1);
  position: relative;
}

footer {
  position: relative;
}

.full-height,
.full-height .overlay:not(.booking-game-info-wrapper) {
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-height .overlay {
  flex: 1;
}

@media screen and (max-width: 890px) {
  .full-height,
  .full-height .overlay:not(.booking-game-info-wrapper) {
    min-height: calc(100vh - 3.11rem);
  }
}

/* Main */

.title-background-wrapper {
  position: relative;
  padding-bottom: 2em;
  padding-top: 0;
  background-size: cover;
  background-position: center;
  min-height: 26rem;
  box-shadow: 2em -1em 5em 5em #101316 inset;
  margin-bottom: 0;
}

.book-now-background-image {
  height: unset;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

/* How to play section */

.how-it-works-video {
  display: block;
  margin: 2em 0;
  max-width: 100%;
  max-height: 100vh;
}

/* When using the Plyr library to play videos, customise player */

:root {
  --plyr-color-main: #dd1515 !important;
  --plyr-control-icon-size: 35px;
}

.plyr__control--overlaid {
  background-color: transparent;
  border-style: solid;
  border-color: white;
  border-width: 7px;
}

@media (max-width: 1000px) {
  :root {
    --plyr-control-icon-size: 15px;
  }
  .plyr__control--overlaid {
    border-width: 4px;
  }
}

@media screen and (max-width: 890px) {
  .how-it-works-video {
    margin: 2em auto;
  }
}

@media (max-width: 992px) {
  .how-trailer {
    max-width: 960px;
  }
}

.how-to-play section.main.top .text-cont .branding {
  flex: 1 1;
}

.how-to-play section.main .text-cont .branding h1,
.how-to-play .video-heading {
  letter-spacing: 0.2rem;
}

.how-to-play.title-background-wrapper + section.black-base {
  padding-top: 2.5rem;
  padding-bottom: 6rem;
}

.how-to-play-steps {
  counter-reset: steps;
  padding: 0 1rem;
}

.how-to-play-steps .step > :first-child:before {
  counter-increment: steps;
  content: counter(steps) ".  ";
  margin-right: 0.35rem;
  display: inline-block;
}

.how-to-play-steps .step {
  align-self: center;
  font-family: "RajdhaniRegular", "Arial", "Helvetica", "sans-serif";
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 0.005em;
  margin-top: 1em;
  margin-bottom: 0.5em;
  flex-direction: row;
  align-items: start;
}

.how-to-outer-container {
  max-width: 64rem;
  margin: 0 auto;
}

.how-to-column {
  margin-bottom: 1rem;
}

.how-to-play-steps img {
  flex: 0 0 2rem;
  margin-right: 0.75rem;
  max-width: 2rem;
  margin-top: 0.25rem;
}

@media screen and (max-width: 768px) {
  .how-to-column {
    padding: 0 0.75rem;
  }
}

@media screen and (max-width: 890px) {
  .how-to-play .trailer-video-controls {
    bottom: 44%;
    top: 56%;
  }

  .how-to-play section.touchzone .trailer-video-controls {
    bottom: 30%;
    top: 70%;
  }

  .how-to-play.title-background-wrapper {
    margin-bottom: unset;
  }
  .how-to-play section.main .text-cont .branding h1 {
    margin-bottom: 3rem;
  }
}

@media (min-width: 1200px) and (min-height: 812px) {
  .how-to-play section.main .text-cont .branding h1,
  .how-to-play .video-heading {
    font-size: 3.5rem;
  }
  .how-to-column {
    padding: 0 1rem;
  }
}

div.title-background-wrapper[style*="tembanner.jpg"] {
  background-position: center top;
}

.title-background-wrapper.overlay::before {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  content: "";
  z-index: 0;
  box-shadow: 2em -1em 5em 5em #101316 inset;
}

.overlay {
  position: relative;
}

.overlay::before {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 7%);
  content: "";
  z-index: 0;
  pointer-events: none;
}

.title-background-wrapper .step {
  position: relative;
  z-index: 1;
}

body h1:first-of-type,
.game-code {
  padding-top: 12.5rem;
  text-transform: uppercase;
  margin-top: 3.125rem;
}

@media screen and (max-width: 890px) {
  body h1:first-of-type,
  .game-code {
    padding-top: 3.5rem;
  }
}

body h1:first-of-type:not(.no-glow) {
  margin-bottom: 3.125rem;
  text-shadow: 0 0 1em white;
  font-size: 50px;
}

@media screen and (max-width: 430px) {
  body h1:first-of-type:not(.no-glow) {
    margin-left: auto;
    margin-right: auto;
    font-size: 45px;
  }
}

.main,
.step {
  align-content: center;
}

.main p,
.step p,
.main-ul li,
.main-ol li,
.questions-block li {
  width: 85%;
  align-self: center;
}

.circle-divider-wrap {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 3em;
  margin-bottom: 3em;
}

.circle-divider-1,
.circle-divider-2,
.circle-divider-3,
.circle-divider-4 {
  width: 0.8em;
  height: 0.8em;
  background: rgb(80, 80, 80);
  border-radius: 50%;
  margin: 0.8em;
}

/* Lists */

.main-ol,
.questions-block {
  list-style: none;
  counter-reset: my-counter 0;
}

.main-ol li,
.questions-block > li {
  counter-increment: my-counter;
}

.main-ol li:before,
.questions-block li:before {
  content: counter(my-counter) ". ";
  color: #dd1515;
  left: 1em;
}

.questions-block {
  text-align: left;
  margin-left: 3.2em;
}

.questions-block-wrapper {
  padding-top: 2em;
}

.questions-block h5 {
  text-align: left;
  margin-top: 0;
}

.questions-block li p {
  width: 100%;
  padding-top: 0;
  padding-bottom: 1em;
}

.main-ul li:before {
  content: url("../images/icons/noun_Circle_167087_dd1515.svg");
  left: 1em;
}

.main-ul,
.main-ol,
.questions-block {
  max-width: 43rem;
  margin: auto;
  padding-top: 3em;
  padding-bottom: 3em;
}

.main-ul > li,
.main-ol > li,
.questions-block > li {
  color: white;
  position: relative;
  padding-left: 3em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.main-ul li:before,
.main-ol li:before,
.questions-block li:before {
  text-shadow: 1px 1px 2px #dd1515;
  position: absolute;
}

/* Stop nested lists from showing double bullets (i.e in FAQs section) */

ol li li {
  list-style: none;
}

.questions-block li li::before {
  content: url("../images/icons/noun_Circle_167087_dd1515.svg") !important;
  left: 1.5em;
}

.main-ul li {
  list-style: none;
}

ul ul {
  display: none;
}

section.main {
  clear: both;
  margin: auto;
}

.symbol {
  margin-top: 5rem;
}

.symbol img {
  max-width: 5rem;
}

.steps,
.main-ul,
.main-ol,
.questions-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 43rem;
}

.step.people-grid-wrapper {
  max-width: 55em;
}

.step .square-image {
  height: auto;
  align-self: center;
  width: 100%;
  margin-bottom: 0.5em;
  margin-top: 1em;
}

.step .square-image:hover {
  transform-style: preserve-3d;
  transform: matrix3d(
    0.999959,
    0,
    0.00904973,
    0,
    -0.0000431656,
    0.999989,
    0.00476963,
    0,
    -0.00904963,
    -0.00476983,
    0.999948,
    0,
    0,
    0,
    0,
    1
  );
}

.step h2,
.step h3 {
  margin: auto;
  max-width: 30rem;
}

a {
  color: #dd1515;
  text-decoration: none;
}

.inline-error {
  color: #dd1515;
}

/* Dividers */

.divider-grad {
  height: 90px;
  margin: -40px 0;
  z-index: 2;
  position: relative;
  pointer-events: none;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 33%,
    rgba(0, 0, 0, 1) 47%,
    rgba(0, 0, 0, 1) 55%,
    rgba(0, 0, 0, 1) 56%,
    rgba(0, 0, 0, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 33%,
    rgba(0, 0, 0, 1) 47%,
    rgba(0, 0, 0, 1) 55%,
    rgba(0, 0, 0, 1) 56%,
    rgba(0, 0, 0, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 33%,
    rgba(0, 0, 0, 1) 47%,
    rgba(0, 0, 0, 1) 55%,
    rgba(0, 0, 0, 1) 56%,
    rgba(0, 0, 0, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

.divider-grad
  + section[class*="vertical-divider-padding"]
  > .heading:first-child {
  position: relative;
  z-index: 3;
}

@media (min-width: 768px) {
  .divider-grad {
    height: 280px;
    margin: -140px 0;
  }
}

.vertical-divider-padding,
section.width-55__pad-bottom.vertical-divider-padding {
  padding-top: 3rem;
  padding-bottom: 2rem;
}

hr.mini-divider {
  max-width: 2rem;
}

/* Cards */

.card-header {
  background-color: hsla(0, 27%, 30%, 0.6);
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Reviews section */

section#reviews {
  /* width: 100vw; */
  position: relative;
}

*:not(.vertical-divider-padding) > section#reviews {
  padding-top: 2rem;
}

section#reviews .heading.epicvoice {
  margin-bottom: 1rem;
}

section#reviews .container .panel-row {
  display: flex;
  flex-wrap: wrap;
}

section#reviews .container .panel-row div.review-provider-wrapper {
  flex: 1 1 100%;
  max-width: calc(100% - 1rem);
  margin: 1rem auto;
  max-width: 100%;
}

/* Prevent Elfsight widget from overflowing */
#lazy-loaded-reviews {
  overflow-x: hidden;
}

#CDSWIDSSP {
  width: calc(100% - 1rem);
  width: 100% !important;
}

section#reviews #CDSWIDSSP .widSSPData .widSSPOneReview .widSSPBullet li span,
#CDSWIDSSP .widSSPData .widSSPH11 {
  font-size: larger;
}

section#reviews #CDSWIDSSP .widSSPData .widSSPH18 {
  font-family: "IntroBold", "Arial", "Helvetica", "sans-serif";
  font-variant: petite-caps;
  font-size: large;
}

.ta-travellers-choice-wrapper {
  display: flex;
  justify-content: center;
  gap: 0 1rem;
  flex-wrap: wrap;
  margin: auto;
}

.travellers-choice {
  height: auto;
  width: calc((100% - 2rem) / 3);
  max-width: 8rem;
  display: block;
  margin: 2rem 0 0;
}

@media (max-width: 768px) {
  .ta-travellers-choice-wrapper {
    padding: 0 1rem;
  }
}

@media (min-width: 768px) {
  section#reviews .container .panel-row {
    flex-wrap: nowrap;
  }

  section#reviews .container .panel-row div.review-provider-wrapper {
    flex: 1 0 calc(50% - 2rem);
    max-width: 50%;
  }

  section#reviews .container .panel-row div.review-provider-wrapper.google {
    width: calc((100% - 468px) - 2rem);
  }

  section#reviews
    .container
    .panel-row
    div.review-provider-wrapper:nth-child(2n) {
    margin-right: 0;
    margin-left: 1rem;
  }

  section#reviews
    .container
    .panel-row
    div.review-provider-wrapper:nth-child(2n-1) {
    margin-left: 0;
    margin-right: 1rem;
  }
}

section#reviews [class^="Header__WidgetTitle"].kwObyu {
  color: white;
}

@media (min-width: 992px) {
  .ta-travellers-choice-wrapper {
    gap: 0 2rem;
  }
  .travellers-choice {
    height: 150px;
    width: auto;
  }
}

/* Book Now > Intro & Outline */

.booking-header-background-image {
  background-image: url("../images/home/cta2-desktop.jpg");
  /* currently inline in block */
  padding-top: 0;
  height: 25em;
  box-shadow: 2em -1em 6em 6em #101316 inset;
}

.booking-game-info-wrapper {
  background-position: center center;
  background-image: url("../images/home/cta1-desktop.jpg");
  border: outset 0px hsla(0, 0%, 17%, 0.7);
  /* currently inline in block */
  background-size: cover;
}

.full-height .booking-outline {
  width: 100%;
}

.special-text {
  background-color: hsla(0, 0%, 0%, 0.75);
}

.special-text p {
  margin: 0.5em 0;
}

.book-now h1 {
  padding-top: 4em;
}

@media screen and (min-width: 890px) {
  .gather-your-dates .select-date-top-wrap {
    margin: 1rem 0 0 0;
  }
}

.gather-your-dates .select-date-top-wrap h4.gamesmaster {
  letter-spacing: 0.15em;
}

.game-details h4 {
  margin-bottom: 1.5em;
  margin-top: 1.5em;
}

.header-description {
  margin: -1em 1em 4em 1em;
}

.header-description p {
  margin: auto;
}

.booking-description {
  padding: 1em 10em;
  display: block;
  background-color: hsla(200, 8%, 8%, 0.75);
  box-shadow: 2em 3em 6em -1em #101316 inset;
  z-index: 1;
  position: relative;
}

.booking-description-info-wrapper {
  display: flex;
  width: 100%;
}

.booking-description-info {
  background-color: hsla(0, 0%, 17%, 0.7);
  /* min-width: 60%; */
  max-width: 60%;
  width: 100%;
  padding: 1em;
  padding-left: 3em;
  padding-right: 3em;
}

.booking-description-info-no-map {
  min-width: 100%;
  max-width: 100%;
}

.booking-description-info p {
  padding-left: 2em;
  padding-right: 2em;
}

.booking-description-info p:last-child {
  margin-bottom: 0;
}

.para-bold {
  font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
  margin-bottom: 0;
}

.para-info {
  margin-top: 0;
}

.para-extra {
  font-size: 16px;
  letter-spacing: 0;
}

.para-info + .para-extra {
  margin-top: -0.75rem;
}

.para-italic {
  font-style: italic;
}

.para-italic,
.booking-description-intro {
  padding-bottom: 2em;
}

.map img,
.booking-calendar img {
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
}

.map-wrapper {
  position: relative;
  flex: 1;
  min-height: 353px;
}

#map {
  text-align: left;
  left: 0px;
  top: 0px;
  z-index: 5;
  position: absolute;
  height: 100%;
  width: 100%;
}

.map canvas {
  width: 100%;
  height: 100%;
}

.map .mapboxgl-popup-content {
  background-color: black;
  color: white;
}

.map .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: black;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

@media screen and (max-width: 890px) {
  .map-wrapper {
    position: relative;
    height: 200px;
    width: 100%;
    flex: unset;
  }
}

/* Marker within map */

.map .map-marker {
  display: block;
  position: absolute;
  bottom: 50%;
  width: 160px;
  left: calc(50% - 80px);
  text-align: center;
  color: white;
  background-color: black;
  z-index: 2;
  padding: 0.5em;
}

.map-marker__link {
  display: block;
  color: inherit;
}

.map .map-marker::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: black transparent transparent transparent;
  position: absolute;
  bottom: -9px;
  left: calc(50% - 5px);
}

/* Hovering anywhere on map changes the marker colour */

.map:hover {
  cursor: pointer;
}

.map:hover .map-marker {
  background-color: grey;
  cursor: pointer;
}

.map:hover .map-marker::after {
  border-color: grey transparent transparent transparent;
}

.select-date {
  padding-top: 0;
}

.select-date.width-43 {
  padding-bottom: 1.5em;
}

.select-date-top-wrap {
  margin: 1rem 1rem 0 1rem;
}

.select-date-top-wrap p {
  text-align: center;
  margin: 0 auto 1.75em;
}

/* .select-date {
  margin-top: 4em;
} */

.select-date > h4,
.select-date-top-wrap > h4 {
  margin: 1em auto 0.25em;
}

.calendar-selection-banner {
  display: block;
  width: 100%;
  background-color: rgb(58, 89, 69);
  padding: 1em;
  font-size: 0.9em;
  position: relative;
}

.calendar-selection-banner h3 {
  display: inline-block;
  margin-top: 0.25em;
  line-height: 2em;
}

#selectedDate {
  color: black;
}

#selected-date > h4 {
  margin: 0.5em 0 0.25em 0;
}

.date-details h4 {
  margin: unset;
}

.calendar-tick {
  vertical-align: middle;
  position: relative;
  right: 1.5em;
  top: -4em;
  float: right;
}

.calendar-tick img {
  height: 2em;
}

.start-game-info.bodyBold {
  font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
  font-size: 1.25em;
  line-height: 1.1;
}

.start-game-info-date.epicvoiceReg {
  font-size: 1.4em;
}

@media screen and (max-width: 890px) {
  .calendar-selection-banner-wrapper {
    margin: 1em 1em 0 1em;
  }

  .calendar-selection-banner {
    padding-bottom: 1em;
  }

  .calendar-selection-banner h4 {
    font-size: 28px;
  }

  .calendar-tick {
    float: unset;
    display: block;
    position: unset;
    margin-top: 1em;
  }

  .calendar-tick img {
    display: block;
    margin: auto;
  }
}

.price-banner {
  background-color: rgba(221, 21, 21, 0.8);
  padding: 0.25em;
  margin-top: 0;
  position: relative;
}

.price-banner p {
  font-size: 1rem;
  margin: 1rem;
}

.icon-wrapper {
  display: inline-block;
  position: relative;
  width: 2em;
  left: 6em;
  bottom: 0;
}

.icon-wrapper img {
  height: 2em;
  width: 2em;
  vertical-align: middle;
}

/* TODO OD-FIX duplicate margin */
.date-details {
  z-index: 99999;
  position: relative;
  background-color: #323232;
  border: solid white;
  margin: auto;
  display: block;
  margin: 1em;
  padding: 1.2em;
  width: 400px;
  max-width: 100%;
}

.date-details img {
  width: 0.8em;
}

.close-button {
  float: right;
  position: relative;
  top: -0.5em;
  left: 0.5em;
  cursor: pointer;
}

.date-details .close-button {
  height: 12.8px;
  margin-bottom: -1em;
}

@media (max-width: 890px) {
  .date-details {
    width: 100%;
    margin: auto;
    margin-top: -1em;
  }
  /* 
  #calendar .date-details {
    transform: translateY(4em);
  }
  
  .date-filters-wrapper {
    transform: translateY(-21em);
  } */
}

.date-details > div:not(:last-of-type) {
  margin-bottom: 1em;
}

.date-details .slot-time-inner {
  column-gap: 2rem;
}

@media (min-width: 890px) {
  .date-details {
    position: absolute;
  }

  .date-details::after {
    width: 0;
    height: 0;
    border-top: 32px solid transparent;
    border-bottom: 32px solid transparent;
    border-left: 32px solid #323232;
    content: "";
    position: absolute;
    right: -27px;
    top: calc(50% - 30px);
  }

  .date-details::before {
    width: 0;
    height: 0;
    border-top: 32px solid transparent;
    border-bottom: 32px solid transparent;
    border-left: 32px solid white;
    content: "";
    position: absolute;
    right: -32px;
    top: calc(50% - 30px);
  }

  .date-details.pop-flip::before {
    border-left: none;
    border-right: 32px solid white;
    left: -32px;
  }

  .date-details.pop-flip::after {
    border-left: none;
    border-right: 32px solid #323232;
    left: -27px;
  }
}

.start-time-option {
  padding: 0.25em;
  background-color: #016a29;
  padding: 0.5em;
  cursor: pointer;
  margin-inline: auto;
  max-width: 100%;
}

@media screen and (min-width: 462px) {
  .start-time-option {
    width: 450px;
  }
}

.choose-slot.start-time-option:hover {
  filter: brightness(1.2);
}

.start-time-option p {
  margin-top: 0;
  margin: 1em;
  padding-left: 2em;
  padding-right: 2em;
}

.remaining-places p {
  font-size: 0.7em;
  color: grey;
  background-color: white;
  text-align: center;
}

/* Book Now > Gift Section */

.surprise-option {
  display: flex;
  justify-content: space-around;
  flex-flow: row nowrap;
  padding-bottom: 0;
}

section.gift-code {
  padding-bottom: 0;
}

.gift-code-block {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.gift-code-para {
  margin-right: 2em;
  margin-bottom: 1em;
}

.gift-code-button {
  margin-left: 2em;
  height: 2em;
}

.gift-voucher-designs {
  display: flex;
  width: 1500px;
  margin: auto;
  padding-bottom: 3rem;
}

.gift-voucher-custom-image {
  img {
    cursor: pointer;
    display: block;
    max-width: 100%;
  }
}

.voucher-style-dropdown {
  display: flex;
  align-items: center;
  min-width: 300px;
  gap: 0.75rem;
  font-weight: 700;
  margin-right: 0.9rem;
}

.voucher-style-dropdown label {
  width: max-content;
  flex-shrink: 0;
}

.voucher-style-dropdown select {
  font-size: 16px;
  font-weight: 700;
  flex-grow: 0;
  flex-shrink: 1;
}

.system-form .voucher-style-dropdown select {
  font-family: revert;
}

#console_static .voucher-style-dropdown select {
  margin-right: 0;
}

#console_static [data-console-component="gift-details"] .gift-spaces-payment .upper-details-players {
  margin-right: 1rem;
}

.mobile-select-voucher-design {
  display: none;
}

.desktop-select-voucher-design {
  display: block;
}

.console-back-gifts-mobile {
  display: none !important;
}

[data-voucher-card] {
  border: transparent solid 2px;
}

[data-voucher-card].active {
  border: #b82026 solid 2px;
}

@media screen and (max-width: 800px){

  [data-console-state="gift-details"] .console-back-gifts-mobile {
    display: block !important;
  }

  .voucher-style-dropdown {
    display:none;
  }

  .mobile-select-voucher-design {
    display: block;
  }

  .desktop-select-voucher-design {
    display: none;
  }

  body[data-voucher-design]  .mobile-select-voucher-design {
    display: none;
  }

  body[data-voucher-design]  .desktop-select-voucher-design {
    display: block;
  }

}

.gift-spaces-payment {
  align-items: center;
}

@media screen and (max-width: 1500px){

  .gift-voucher-designs {
    display: block;
    width: 360px;
    margin: auto;

    img {
      display: block;
      margin-bottom: 1rem;
    }

  }

}

@media screen and (max-width: 890px) {
  .gifts-block-info {
    margin: 1em;
  }
}

.surprise-option-text {
  width: 80%;
  padding: 1em;
  margin: auto;
  position: relative;
}

.code-accepted {
  background-color: #323232;
  display: block;
  width: 20em;
  padding: 2em;
  margin: auto;
}

.gift-code-input {
  width: 20em;
  height: 3em;
  border: none;
  border-radius: 0.3em;
  padding-left: 1em;
}

@media screen and (max-width: 890px) {
  .gift-code-button-wrapper {
    margin-top: 1em;
  }

  .gift-code-input {
    width: 14em;
  }
}

.howDidYouHearAdditional {
  display: none;
}

#howDidYouHearAdditional {
  display: block;
  padding: 1em;
  width: 100%;
  margin-top: 1em;
  height: 3em;
  font-family: "RajdhaniRegular", "Arial", "Helvetica", "sans-serif";
  font-size: 18px;
}

.howDidYouHearAdditional {
  margin-top: 1em;
}

input::placeholder,
select {
  font-family: "RajdhaniRegular", "Arial", "Helvetica", "sans-serif";
  font-size: 18px;
  /* line-height: 1.4; */
  /* letter-spacing: 0.01em; */
  /* margin-top: 1em; */
  /* margin-bottom: 0.5em; */
}

.relative {
  position: relative;
}

[data-validate] {
  position: relative;
}

[data-validate]::after {
  content: "";
  position: absolute;
  display: inline-block;
  background-size: contain;
  width: 1.8em;
  height: 2.2em;
  right: 0.3em;
  bottom: 0.3em;
  background-repeat: no-repeat;
  background-image: url("../images/question-mark-icon.png");
}

.validation-message {
  background-color: #323232;
  display: block;
  width: 10em;
  padding: 1.5em;
  margin: auto;
  color: white;
  position: absolute;
  right: -181px;
  z-index: 10;
}

.validation-message:before {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid rgb(80, 80, 80);
  content: "";
  position: absolute;
  left: -20px;
  top: calc(50% - 20px);
}

/* Book Now > Already signed in message */

.already-signed-in-message img,
.already-signed-in-message p {
  display: inline-block;
  vertical-align: middle;
}

.already-signed-in-message img {
  height: 6em;
  margin-right: 2em;
}

.already-signed-in-message p {
  max-width: 30em;
}

@media screen and (max-width: 890px) {
  section.already-signed-in-message {
    display: block;
    padding-top: 3em;
  }

  .already-signed-in-message img {
    display: block;
    margin: auto;
  }
}

@media (max-width: 890px) {
  .validation-message {
    right: 0px;
    padding: 0.5em;
    top: 35px;
  }

  .validation-message:before {
    display: none;
  }

  .validation-message:after {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid rgb(80, 80, 80);
    content: "";
    position: absolute;
    right: 5px;
    top: -15px;
  }
}

.need-to-know [data-validate]::after {
  bottom: -0.3em;
}

[data-validate][data-validated="completed"]::after {
  background-image: url("../images/green-tick.png");
}

[data-validate][data-validated="incorrect"]::after {
  background-image: url("../images/red-alert-icon.png");
}

.add-new-info-input > .team-card {
  min-width: 95%;
}

.booking-key,
.booking-review-top,
.booking-review-bottom {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.gamesmaster.key-text {
  padding-right: 1em;
}

#submitBooking.active {
  opacity: 0.5;
}

.booking-submit-errors {
  background-color: #dd1515;
  color: white;
  padding: 1em;
  position: relative;
  top: 150px;
  z-index: 10;
}

.booking-calendar-top {
  display: block;
  width: 100%;
  margin: auto;
}

.key-filter-card {
  position: relative;
}

.key-filter-card-header,
label.booking-accordion-icons {
  display: none;
}

input.booking-accordion-icons,
img.booking-key-filter-icon {
  width: 2.5em;
  height: 2.5em;
  position: absolute;
}

input.booking-accordion-icons {
  opacity: 0;
  z-index: 3;
}

input.booking-accordion-icons,
img.booking-key-filter-icon {
  top: 0.8em;
  right: 0.8em;
}

.circle {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin: 0 0.75em 0 0;
  padding: 0.5em;
  display: inline-block;
}

.circle-available {
  background: #016a29;
}

.circle-low {
  background: hsl(34, 100%, 50%);
}

.circle-soldout {
  background-color: rgb(53, 13, 14);
  color: rgb(75, 47, 47);
}

.circle-unavailable {
  background-color: #434343;
  color: rgb(46, 46, 46);
}

.fc-day-number.circle-unavailable:hover {
  border: none;
  border-style: none;
}

.date-filter {
  display: block;
  margin: auto;
  text-align: center;
}

/* Checkboxes */

/* Booking review checkboxes - left-aligned */

.booking-review-bottom-right input[type="checkbox"] + label::before {
  content: "";
  width: 1em;
  height: 1em;
  display: inline-block;
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
  border-style: solid;
  border-color: darkgrey;
  border-width: 1px;
  vertical-align: middle;
}

.booking-review input[type="checkbox"] + label::before {
  margin-right: 0.5em;
}

.booking-review input:checked + label::before {
  background-image: url("../images/check-icon.png");
}

.booking-review-bottom-right input[type="checkbox"] + label::after {
  display: none;
}

.checkbox-text {
  position: relative;
  bottom: -1em;
  left: 0;
  display: inline-block;
}

/* Generic checkboxes */

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label::after {
  content: "";
  width: 1em;
  height: 1em;
  display: inline-block;
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
  border-style: solid;
  border-color: #323232;
  border-width: 1px;
  vertical-align: middle;
  margin-left: 0.5em;
}

input:checked + label::after {
  background-image: url("../images/check-icon.png");
}

input[type="checkbox"].left-checkbox + label::after,
input.real-checkbox[type="checkbox"] + label.replaced-check::after {
  display: none;
}

input[type="checkbox"].left-checkbox + label::before {
  content: "";
  width: 1em;
  height: 1em;
  display: inline-block;
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
  border-style: solid;
  border-color: #323232;
  border-width: 1px;
  vertical-align: middle;
  margin-right: 0.5em;
  cursor: pointer;
  transition: all 0.5s ease;
}

input[type="checkbox"].left-checkbox + label:hover::before {
  background-color: #353535;
}

input:checked.left-checkbox + label::before {
  background-image: url("../images/check-icon.png");
}

label.accordion::after {
  display: none !important;
}

/* .booking-key, */
.date-filters-wrapper {
  padding: 0.5em 0;
  flex-flow: row wrap;
  flex-grow: 1;
  justify-content: left;
}

.date-filter label {
  min-width: 14em;
  text-align: right;
  line-height: 1.25;
}

.booking-key span {
  display: inline-flex;
  /* width: 11.5em; */
  justify-content: left;
  padding-bottom: 1em;
  line-height: 1.75;
}

.booking-review-checkbox:first-of-type {
  position: relative;
  top: -1em;
}

.date-filter input {
  right: 0;
  top: 0;
}

.date-filter .tick img {
  left: 16.5em;
}

.surprise-checkbox {
  top: -0.4em;
  left: 12em;
}

.surprise-checkbox-frame {
  right: 8.5em;
  bottom: 1.9em;
  border: solid thin #323232;
}

.booking-review-checkbox-frame-top {
  height: 1.5em;
  width: 1.5em;
  border: solid thin rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0.7em;
  left: 0;
}

.booking-review-checkbox-frame-bottom {
  top: -0.2em;
  left: 0;
}

.booking-review-checkbox img,
.subscribe-checkbox {
  left: 0;
  top: 0;
}

.booking-review-checkbox label {
  position: relative;
  left: 2.2em;
}

@media screen and (max-width: 890px) {
  .checkbox-text {
    bottom: 0;
  }

  .booking-review input[type="checkbox"] + label::before {
    margin-right: 0.5em;
    display: block;
    margin-bottom: 1em;
  }

  .booking-review-bottom .booking-review-bottom-right {
    padding-top: 0;
  }
}

/* Team Form Block */

.subheading {
  margin-bottom: 1em;
}

.player-numbers div {
  display: inline-block;
  border: solid thin red;
  line-height: 0.5em;
  padding: 1em;
  margin-right: 0.5em;
  border-radius: 50%;
  height: 2.5em;
  width: 2.5em;
}

.player-numbers div {
  vertical-align: middle;
}

.player-numbers span {
  cursor: pointer;
}

.half-opacity {
  opacity: 0.5;
}

.team-form-block {
  padding-top: 0;
}

.team-form-head-wrap {
  margin-bottom: 2.5rem;
}

.team-form-head-wrap h4 {
  margin-bottom: 0.5em;
}

@media screen and (max-width: 890px) {
  .team-form-block .subheading,
  .team-form-block .player-numbers {
    margin: 1em;
  }

  .team-form-block h6 {
    padding-bottom: 1em;
  }

  .team-form-block > .form-buttons {
    padding-top: 1em;
    text-align: center;
  }
}

/* Hacky fix for spacing below cards - TODO: fix */

.contact-details-cards-wrapper {
  margin-bottom: -150px !important;
}

.form-top.contact-details-card {
  background-color: unset;
  margin-bottom: 0;
}

.form-top > span > div,
.form-bottom > span > div {
  margin-top: 0.5em;
}

.h6-card-background h6 {
  margin-top: 0;
}

.contact-details-card .add-new-info-input {
  margin-top: 0;
}

.form-wrapper > div:nth-child(2) {
  display: flex;
}

.form-item {
  position: relative;
}

.form-item.no-left-padding {
  padding-left: 0;
}

.form-item.no-right-padding {
  padding-right: 0;
}

.form-bottom {
  display: flex;
}

.form-wrapper.team-form div.form-bottom.contact-details-card {
  background-color: unset;
}

.form-item.add-new-info-input:first-child {
  flex: 2;
}

.form-item.add-new-info-input:nth-child(2) {
  flex: 1.5;
}

.form-item.add-new-info-input:nth-child(3) {
  flex: 1.5;
}

.card-title-para {
  font-size: 1.5em;
  font-weight: bolder;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
}
/* 
.booking-key > span:nth-child(2) {
  order: 1;
}

.booking-key > span:nth-child(4) {
  order: 2;
} */

.date-filters-wrapper {
  justify-content: right;
  margin-bottom: 1em;
}

.pay-now {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 2em;
}

/* Book Now > Your Teams */

.remove-team {
  margin: 0;
}

.surprise-option-image {
  display: flex;
  justify-content: space-around;
  width: 80%;
}

.surprise-option-image img {
  width: 100%;
  padding: 3em;
  align-self: center;
}

.surprise-option-text {
  width: 100%;
  margin: 2em;
}

/* Book Now > Add People */

.add-people-button {
  padding: 1em;
  margin-bottom: 2em;
}

.add-people-description > p,
.review-game-details-description > p {
  padding-left: 6em;
  padding-right: 6em;
}

@media screen and (max-width: 890px) {
  .add-people-description > p,
  .review-game-details-description > p {
    padding-left: unset;
    padding-right: unset;
  }

  .review-game-details-description {
    padding-top: 7em;
  }

  .contact-details-card.need-to-know {
    padding-bottom: 1em;
    margin-bottom: 1em;
  }
}

.review-game-details {
  padding-bottom: 0;
}

/* @media screen and (max-width:890px) {
  .team-card-header-right {
    position: relative;
    bottom: -20.75em;
    left: -11em;
  }
} */

.team-card-header-right {
  position: relative;
}

.remove-team-button {
  position: absolute;
  right: 1em;
  top: 0;
  z-index: 2;
}

@media screen and (max-width: 890px) {
  .add-people-button {
    width: 100%;
  }
}

/* Book Now > Review Game Details */

.booking-review {
  border: solid thin red;
}

.booking-review-top,
.pay-now {
  background-color: #2e2e2e;
}

.booking-review-bottom {
  background-color: #323232;
}

.booking-review-bottom-right {
  padding-top: 3.5em;
}

.booking-review-top p,
.pay-now h5 {
  margin: 0;
}

.booking-review-bottom-left,
.booking-review-bottom-right,
.booking-review-top-left,
.booking-review-top-right {
  width: 50%;
}

.booking-review-top-left,
.booking-review-top-right,
.booking-review-bottom-left {
  padding: 2em;
}

.add-new-team {
  display: block;
  width: 100%;
  margin: auto;
}

.add-new-team-subheading li {
  display: inline;
  margin-right: 0.5em;
  padding: 0.5em;
  border: solid thin;
  border-radius: 50%;
  vertical-align: middle;
}

.new-info-input-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}

.add-new-info-input {
  padding: 1em;
  width: 100%;
  border-radius: 0.3em;
  border: none;
  margin-top: 1em;
}

/* Country code and phone number */

.form-item.add-new-info-input:first-child.country-code {
  width: 25%;
  flex: initial;
}

.form-item select {
  font-size: 1em;
}

.form-itme.contact-number {
  flex: 1;
}

@media (max-width: 890px) {
  .form-item.add-new-info-input:first-child.country-code {
    width: 100%;
  }
}

.add-new-info-input label i {
  font-size: 0.8em;
  font-style: italic;
  display: inherit;
}

.add-new-team-top,
.add-new-team-bottom,
.add-new-team-buttons {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-between;
}

.add-new-team-buttons {
  margin-top: 1em;
}

.cancel-button,
.add-team-button,
.leaderboard-button {
  background-color: #323232;
  color: white;
  border: none;
  padding-left: 5em;
  padding-right: 5em;
  padding-top: 1em;
  padding-bottom: 1em;
}

#selected-date .form-buttons {
  display: flex;
}

@media screen and (max-width: 890px) {
  #selected-date .form-buttons .button {
    margin-top: 1rem;
    margin-bottom: 0;
    width: 100%;
    flex: 1 1 50%;
  }
}

@media screen and (max-width: 600px) {
  #selected-date .form-buttons {
    display: block;
  }
}

.leaderboard-wrapper > .background-image img {
  opacity: 0.7;
}

.cancel-button-wrapper .x-icon {
  position: relative;
  left: 3em;
}

.add-new-team-bottom {
  padding-top: 2em;
}

.add-new-team-bottom .add-new-info-input-element {
  flex-grow: 1;
}

.add-new-info-input-element:not(:last-child) {
  margin-right: 1em;
}

.contact-details-card {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: space-between;
  align-items: baseline;
  background-color: #323232;
  margin-bottom: 1em;
  position: relative;
}

.form-item.add-new-info-input.no-left-padding.no-right-padding {
  background-color: none;
}

.need-to-know .add-new-info-input {
  position: relative;
  bottom: -1em;
  left: 0;
  padding-right: 0;
}

.need-to-know.fast-need-to-know .add-new-info-input {
  bottom: -0.5em;
}

.need-to-know .team-card {
  margin-left: 0;
  margin-top: 0;
}

.h6-card-background {
  background-color: #462828;
  padding-bottom: 5.4em;
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
}

.add-another-button {
  float: right;
}

/* Contact Us  & Gifts Block*/

.contact-us-block,
.gifts-block {
  max-width: 35em;
  margin: auto;
}

.contact-us-wrapper .form-item,
.gift-wrapper .form-item {
  padding: 1em;
  margin-top: 0;
  padding-top: 0;
}

input.message {
  height: 20em;
}

.contact-info {
  padding-bottom: 2em;
}

.submit-enquiry {
  margin: 3em;
  text-align: center;
}

@media screen and (max-width: 890px) {
  .contact-info {
    margin: 1em;
  }
}

/* Logged In Page */

/* TODO OD-FIX invalid URL */
.logged-in-background-image {
  background-image: url("../images/home/cta3-desktop.jpg");
  padding-bottom: 4em;
  padding-top: 0;
  height: 35em;
  box-shadow: 2em -1em 6em 6em #101316 inset;
}

.quit-banner h6 {
  background: red;
  padding: 1em;
  margin-bottom: 0;
}

.quit-game-block.width-55__pad-bottom {
  margin: auto;
  padding-top: 4em;
}

.quit-body {
  background-color: white;
  color: #323232;
  padding-top: 5em;
  padding-bottom: 5em;
  padding-left: 12em;
  padding-right: 12em;
}

.contact-us-block > form > input.confirm-quit-button {
  line-height: 0.5;
}

.quit-button a {
  margin: auto;
  margin-left: 1em;
  width: 10em;
  justify-content: center;
}

.detailed-results-table {
  display: none;
}

.detailed-results-table[data-active] {
  display: block;
}

.detailed-results-links {
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.detailed-results-team {
  cursor: pointer;
  display: inline-block;
  width: 14em;
  margin-top: 1em;
}

.detailed-results-team[data-active] {
  color: red;
}

.detailed-results-team p {
  border: solid thin white;
  display: inline-block;
  padding: 0.5em;
}

@media screen and (max-width: 600px) {
  .quit-game-block.width-55__pad-bottom {
    margin-left: 1em;
    margin-right: 1em;
    padding-top: 0;
  }

  .quit-body {
    padding-left: 1em;
    padding-right: 1em;
  }
}

/* Saved game banner */

.saved-game-banner {
  display: block;
  width: 100%;
  padding: 1em;
  padding-top: 1.5em;
  padding-bottom: 0;
  font-size: 0.9em;
  background-color: #323232;
  position: relative;
}

.saved-game-banner h6 {
  margin: 0.5em;
  font-size: 14px;
}

.saved-game-banner .pay-now-button {
  display: inline-block;
  position: relative;
  width: 6em;
  left: 51em;
  bottom: 2em;
}

/* Team Cards */

.team-card-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0.5em;
}

.team-card-button {
  position: relative;
  background-color: #323232;
  color: white;
  border: none;
  float: right;
  margin: 0.5em 0 1em;
  cursor: pointer;
}

.team-card-body {
  padding: 1em;
}

@media screen and (max-width: 890px) {
  .team-card-body > ul > li > span {
    display: block;
    margin-bottom: 0.5em;
  }
}

.x-icon {
  vertical-align: middle;
  height: 1em;
  padding-left: 0.5em;
}

.team-card,
.add-new-team-card {
  background-color: #323232;
  padding-top: 0;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 280px;
  width: calc((100% / 2) - 1em);
  margin: 0.5em;
}

.team-card-wrapper:last-of-type::after {
  content: "";
  width: 50%;
}

.team-card.add-first-team-card {
  margin: auto;
  padding-left: 2em;
  padding-right: 2em;
}

.team-card-header p,
.team-card-button,
.remove-team-button p {
  font-size: 0.8em;
}

.team-card ul,
.ready-summary-card ul {
  list-style: none;
}

.team-card ul li,
.ready-summary-card ul li {
  padding-bottom: 1em;
}

.arrow-icon img {
  fill: rgb(233, 233, 233);
  vertical-align: middle;
  width: 1.5rem;
}

@media screen and (max-width: 890px) {
  #burger-toggle
    > div.sign-in-wrapper.sign-in-wrap-mobile
    > a
    > span.arrow-icon {
    background: none;
    display: inline;
  }
}

.team-card-button.team-card-button-booking-section {
  position: relative;
}

.add-new-team-card {
  padding: 5.5em;
  flex-grow: 0;
}

.edit-team {
  padding-top: 0;
  padding-bottom: 0;
}

.edit-team h5 {
  padding-top: 2em;
}

.edit-team-card {
  background-color: unset;
}

.edit-team.add-new-info-input {
  padding-left: 0;
  padding-top: 0;
}

.edit-team.add-new-info-input:nth-child(3) {
  padding-right: 0;
  padding-left: 1em;
}

.edit-team.add-new-info-input:nth-child(2) {
  padding-right: 0;
}

.edit-team-buttons-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 1em;
}

.leaderboard-button.cancel-button {
  margin-left: 0;
}

@media screen and (max-width: 600px) {
  /* MQ - Team cards - under 600px */
  .add-new-team-card {
    flex-grow: 1;
  }

  /* Saved game banner */
  .saved-game-banner .pay-now-button {
    left: 0;
    bottom: 0;
  }

  .saved-game-block .saved-game-banner {
    margin-left: 1em;
    margin-right: 1em;
  }
}

/* Ready Logged In Section */

.ready-logged-in-banner {
  background-color: green;
}

.saved-game-block,
.ready-summary-card {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  margin: auto;
}

.ready-summary-card {
  background-color: white;
  color: black;
  width: 100%;
}

.ready-summary-card-body {
  width: 30em;
}

.ready-summary-card-body ul li {
  padding-top: 1em;
}

.ready-summary-card-image img {
  max-width: 100%;
}

/* Awaiting Payment Page */

.awaiting-payment-block {
  padding-top: 4em;
}

.awaiting-payment-header {
  padding: 1em;
  background-color: rgb(80, 80, 80);
}

.awaiting-payment-body {
  background-color: rgb(90 90 90);
  padding: 4em;
}

.awaiting-payment-content {
  max-width: 43em;
  margin: auto;
}

.awaiting-payment-content > p {
  margin-bottom: 1em;
}

.circle-red {
  background-color: red;
  max-width: 1px;
  max-height: 1px;
  padding: 0.2em;
  vertical-align: middle;
  margin-right: 0;
}

@media screen and (max-width: 890px) {
  .display-none-mobile {
    display: none;
  }
}

/* Payment Complete */

.logged-in-payment-complete {
  padding-top: 6em;
}

@media screen and (max-width: 890px) {
  .awaiting-payment-block {
    margin-left: 1em;
    margin-right: 1em;
    padding-top: unset;
  }

  .awaiting-payment-body {
    padding: 1em;
  }
}

/* Payment Complete Page */

.payment-complete-banner {
  background-color: #3d8d4f;
  padding: 0.5em;
}

.payment-success-message-wrapper {
  text-align: center;
  position: relative;
  bottom: 0;
  right: 0;
  margin: 3rem auto 0rem;
  padding-top: 3rem;
}

.payment-success-tick,
.payment-success-message {
  display: inline-block;
  text-align: center;
  margin: auto;
}

.payment-success-tick img {
  height: 2em;
  margin-right: 1em;
  vertical-align: middle;
}

@media screen and (max-width: 890px) {
  .payment-success-tick,
  .payment-success-message {
    display: block;
  }

  .payment-success-tick img {
    margin-bottom: 1em;
    margin-right: 0;
  }
}

.payment-complete-body {
  display: flex;
  flex-flow: row nowrap;
  background-color: white;
  color: black;
  justify-content: space-around;
}

.time-per-clue {
  background-color: lightgray;
}

.payment-complete-item {
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 0;
  width: calc((100% / 3) - 1em);
}

.payment-complete-item p {
  margin-top: 0;
}

.payment-complete-item p.description {
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 1em;
}

.rank p.para-bold.gray {
  margin-bottom: 1em;
}

.double-line-height {
  min-height: 2.75em;
}

/* TODO OD-FIX invalid value 0.5 */
.payment-complete-item .results {
  font-size: 3em;
  margin-top: 0.5;
  margin-bottom: 0;
  padding-top: 0.5em;
}

.results {
  font-size: 22px;
}

@media screen and (max-width: 890px) {
  .logged-in-payment-complete:not(.hearsay) {
    margin-left: 1em;
    margin-right: 1em;
  }

  .payment-complete-item {
    display: block;
    width: 100%;
  }

  .payment-complete-body {
    flex-flow: row wrap;
  }

  .payment-complete-item p {
    padding-bottom: 0.5em;
    padding-top: 1.5em;
  }

  /* p.epicvoice.results.highest-rank {
    padding-bottom: 0;
  } */
  .payment-complete-item.rank > .highest-rank {
    padding-bottom: 0;
  }

  .payment-complete-item.rank > p.gray:last-of-type {
    padding-top: 0;
  }
}

/* Payment Complete Page > Results */

.game-completed.leaderboard-intro {
  padding-top: 1em;
  padding-bottom: 0;
  padding-left: 6em;
  padding-right: 6em;
}

@media screen and (max-width: 890px) {
  .game-completed.leaderboard-intro {
    margin: 1em;
    padding-left: 0;
    padding-right: 0;
  }

  body > div:nth-child(12) > section > div > h4.leaderboard-hunt-title,
  body > div:nth-child(13) > section > div > h4.leaderboard-hunt-title {
    text-align: center;
  }
}

.team-results-block,
.detailed-results-block {
  padding-top: 3em;
  padding-bottom: 1em;
}

.detailed-results-block h2 {
  margin-top: 1em;
}

.team-results-block ul li {
  list-style: none;
}

.team-results-wrapper,
.team-results-body-left {
  display: flex;
  flex-flow: row nowrap;
}

.results-item-left,
.team-results-item-right {
  padding: 1em;
}

@media (max-width: 890px) {
  .results-item-left {
    text-align: center;
  }
}

.team-results-wrapper * {
  width: 100%;
}

.results-item-left *,
.results-item-right * {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.results-item-left.number {
  background-color: #462828;
  width: unset;
  padding-top: unset;
}

.name-and-status {
  background-color: #2b2b2b;
  min-width: 30%;
}

.answers-and-hints,
.hints-and-skips {
  background-color: #313131;
}

.team-results-body-left {
  min-width: 70%;
}

.team-results-body-right {
  display: flex;
  flex-flow: column nowrap;
  background-color: #323232;
}

.team-results-item-right:nth-child(even) {
  background-color: #2b2b2b;
}

.team-results-item-right {
  padding: 1em;
  background-color: #373737;
}

@media screen and (max-width: 890px) {
  .team-results-wrapper,
  .team-results-body-left {
    flex-flow: row wrap;
  }

  .results-item-left.number {
    width: 100%;
  }

  .results-item-left.number h5 {
    text-align: left;
    margin: auto;
  }
}

@media screen and (max-width: 890px) {
  :not(.divider-grad) + section.main {
    margin-top: 3.5em;
  }

  .main.step h1 {
    font-size: 2em;
    margin: 1em;
  }

  .floating ~ .title-background-wrapper section.main.step h1:first-of-type {
    padding-top: 7rem;
  }

  .steps {
    margin-top: 12em;
  }

  .width-55__pad-bottom:not(.your-teams):not(.happy-padding):not(
      .tiny-vertical-padding
    ):not(.vertical-happy-padding) {
    padding-top: 0;
  }

  .width-55__pad-bottom:not(.your-teams):not(.happy-padding):not(
      .tiny-vertical-padding
    ):not(.vertical-happy-padding):not(.happy-padding-bottom) {
    padding-bottom: 0;
  }

  /* MQ - UL */
  .main-ol li:before,
  .questions-block li:before {
    left: 1.5em;
  }

  .main-ul > li {
    padding-left: 2.5em;
  }
}

/* FAQ */

details p {
  width: 100%;
  margin: 1em auto;
}

.anchor {
  position: relative;
  top: -60px;
}

/* Sign Up Page */

/* .add-people-description > p,
.review-game-details-description > p {
  padding-left: unset;
  padding-right: unset;
} */

@media screen and (max-width: 890px) {
  body h1:first-of-type {
    padding-left: unset;
    padding-right: unset;
  }

  .main.step {
    text-align: center;
  }
}

.add-people-description {
  margin-bottom: 0;
}

.add-people-description > p {
  margin-bottom: 1em;
}

/* Sign in page */

.sign-in {
  padding: 2em;
}

.sign-in input {
  width: 100%;
  height: 4em;
  border: none;
  border-radius: 0.3em;
  margin-bottom: 10px;
  font-size: 15px;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.sign-in label {
  text-align: left;
  margin-bottom: 0.2em;
  display: block;
}

#error {
  color: #dd1515;
  padding: 1em;
}

[data-failed] input {
  border: 2px solid #dd1515;
}

/* Add new team */

.add-new-team-card {
  padding: unset;
  padding-top: 5.5em;
  padding-bottom: 5.5em;
}

.add-new-team-card.single-player-warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
}

.add-new-team-card.single-player-warning p.text-center {
  margin: 0.5em 0;
}

.player-numbers {
  width: 80%;
}

.player-numbers div {
  margin-top: 1em;
}

@media screen and (max-width: 890px) {
  /* MQ - Book Now - under 890px */
  .booking-outline,
  .gift-code-block,
  .add-people,
  .review-game-details {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .gift-code.width-55__pad-bottom {
    padding-top: 0;
    padding-bottom: 0;
  }

  .booking-header-background-image {
    height: 20em;
  }

  .booking-description {
    padding: 2em 2em 0.5em 2em;
  }

  .para-italic,
  .booking-description-intro {
    line-height: 1.5em;
    padding-bottom: 0;
  }

  .booking-description-info-wrapper {
    flex-wrap: wrap;
  }

  .booking-description-info {
    background-color: hsla(200, 8%, 8%, 0.75);
    padding-top: 0;
    min-width: 100%;
    padding: 2em 0 2em 0;
    z-index: 1;
  }

  .game-details h4 {
    margin-bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(19, 21, 22, 0.75) 100%
    );
  }

  .gift-code-block {
    border: none;
    padding: 0;
    margin-top: 3em;
  }

  #fast-track-box .gift-code-block {
    margin-top: 0;
  }

  .gift-code-para {
    margin: auto;
    display: block;
    padding-bottom: 1em;
  }

  .gift-code {
    display: block;
    width: 100%;
    margin: auto;
    border: none;
  }

  .gift-code-letters {
    max-width: 100%;
    padding-left: 6em;
    padding-right: 6em;
    padding-top: 4em;
    font-size: 0.9em;
    margin: auto;
    padding: 1em;
  }

  .gift-code-letters > div {
    width: 1.5em;
    height: 1.5em;
  }

  .booking-calendar-top,
  .booking-key {
    display: block;
  }

  .booking-calendar-top {
    margin: 1em;
    padding-top: 0;

    display: block;
    width: 100%;
    margin: auto;
  }

  .booking-calendar {
    margin: 1em;
  }

  .fc-day-number {
    max-width: 1em;
    max-height: 1em;
    justify-content: space-between;
  }

  .key-filter-card {
    margin: 1em;
  }

  .key-filter-card-header {
    display: flex;
  }

  .key-filter-card-header h6 {
    font-size: 20px;
    margin: 0.75em 0.25em;
  }

  .booking-accordion-collapse {
    background-color: hsla(0, 0%, 20%, 0.3);
    width: 100%;
    max-width: unset;
    padding: 1em;
  }

  label.booking-accordion-icons {
    display: unset;
  }

  label .booking-key-filter-icon.minus-icon {
    display: none;
  }

  label .booking-key-filter-icon.plus-icon {
    display: inline-block;
  }

  .key-filter-card input:checked + label img.booking-key-filter-icon.minus-icon,
  .key-filter-card input:checked ~ div.booking-accordion-collapse {
    display: inline-block;
  }

  .key-filter-card input:checked + label img.booking-key-filter-icon.plus-icon,
  .key-filter-card .booking-accordion-collapse {
    display: none;
  }

  .booking-key {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
  }

  span.key-text {
    display: block;
    min-width: 100%;
    text-align: center;
    margin-bottom: 0.5em;
  }

  .booking-key span {
    margin: auto;
    padding-bottom: 0;
    padding: 0.5em;
  }

  .date-filter {
    flex-direction: column;
  }

  .date-filter input {
    width: 1em;
    margin-left: 0;
  }

  .date-filter label {
    display: inline-block;
    text-align: left;
    margin-left: 1em;
  }

  .surprise-option {
    display: block;
    border: none;
  }

  .surprise-option-image,
  .review-game-details > p {
    width: 100%;
  }

  .surprise-option-text {
    margin: 0;
  }

  .add-people {
    padding-left: 0;
    padding-right: 0;
  }

  .h6-card-background {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }

  .booking-review-bottom-left,
  .booking-review-bottom-right,
  .booking-review-top-left,
  .booking-review-top-right {
    width: 100%;
  }

  .booking-review-checkbox label {
    display: block;
    width: 90%;
  }

  .booking-review-top,
  .booking-review-bottom {
    display: block;
  }

  .booking-review-top-right {
    padding-top: 0;
  }

  .booking-review-top-right > p:not(.para-bold) {
    padding-bottom: 1em;
  }

  .booking-review-top-left {
    padding-bottom: 0;
  }

  .pay-now {
    display: block;
  }

  .pay-now h5 {
    padding-bottom: 1em;
  }

  .pay-now-button {
    width: 100%;
    padding: 1em;
  }

  .add-new-team-subheading li {
    display: inline;
    margin-right: 0.5em;
    padding: 0.5em;
    border: solid thin;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    margin: 0.5em;
  }

  .add-new-team-subheading ul {
    list-style: none;
    padding-top: 1em;
    padding-bottom: 2em;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  .add-new-team-top,
  .add-new-team-bottom,
  .contact-details-cards-wrapper,
  .contact-details-card {
    display: block;
    min-width: 100%;
    margin: auto;
  }

  .add-new-info-input-element {
    margin-right: 0;
    margin-bottom: 2em;
  }

  .add-new-info-input {
    padding-bottom: 1em;
    margin-bottom: 0;
    margin-top: 0.5em;
    display: block;
  }

  .form-item.no-left-padding {
    padding-left: 1em;
  }

  .form-item.no-right-padding {
    padding-right: 1em;
  }

  .form-item,
  .form-buttons,
  .form-wrapper > div:nth-child(2) {
    display: block;
    width: 100%;
  }

  .form-wrapper > div:nth-child(2) {
    margin-top: 0;
  }

  .form-buttons {
    margin: auto;
  }

  .team-form-block .form-buttons {
    padding: 1rem;
  }

  .cancel-button,
  .add-team-button,
  .add-new-team-buttons {
    display: block;
    width: 100%;
  }

  .cancel-button,
  .add-team-button {
    margin-bottom: 2em;
  }

  .add-another-button {
    display: block;
    width: 90%;
    margin: auto;
    margin-top: 2em;
    padding: 1em;
    float: none;
  }

  /* .contact-details-card {
    margin-top: 2em;
  } */

  .h5-card-background {
    padding: 1em;
  }

  .h5-card-background > h5 {
    transform: none;
    padding-bottom: 0;
    margin: 0;
  }

  .booking-review {
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  /* MQ - Gift Code - under 600px */
  .gift-code-letters {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 400px) {
  /* MQ - Footer - under 400px */
  footer .social-icon {
    max-width: 2.8em;
  }
}

/* System forms */

.system-form {
  padding: 1em;
}

.system-form label {
  font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
  margin-bottom: 0;
  display: block;
}

.system-form input,
.system-form select,
.system-form textarea,
#gift_voucher_dto_giftVoucher select {
  font-family: "RajdhaniRegular", "Arial", "Helvetica", "sans-serif";
  padding: 1em;
  width: 100%;
  border-radius: 0.3em;
  border: none;
  margin-top: 1em;
  margin-bottom: 1em;
}

.system-form select {
  height: 3em;
  padding: 0.75em 1em;
}

/* Payment Form */

#card-element.StripeElement {
  margin-bottom: 1em;
}

/* Min font size to prevent zoom on iOS */

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

/* Star ratings */

.star-rating-star {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: 50px 50px;
  background-image: url("../images/icons/star.svg");
}

.stars {
  margin-bottom: 2em;
}

.stars.half .star-rating-star:last-child {
  width: 25px;
}

/* Payment */
.payment-problem {
  color: red;
}

/* Gather your team */

.gather-your-team-wrapper .social-icon {
  width: 5em;
  height: 5em;
}

#shareText {
  padding: 1em;
}

/* Fast track booking */

.fast-book-head h2 {
  text-transform: uppercase;
  margin-top: 1.5em;
  margin-bottom: 1em;
  max-width: 43rem;
}

.booking-box {
  /* padding: 2em; */
  padding: 2em 4em;
  background-color: hsla(200, 8%, 8%, 0.75);
  box-shadow: 2em 3em 6em -1em #101316 inset;
  z-index: 1;
  position: relative;
}

.booking-box h5,
.booking-box h4 {
  margin: 1rem 0;
}

#fast-track-box.booking-box .gamesmaster {
  font-family: "GoboldExtra1", "Arial", "Helvetica", "sans-serif";
  margin-bottom: 0.25em;
}

.booking-box h5 {
  font-size: 1.2rem;
  text-align: left;
  margin-right: 1em;
}

.booking-box h4 {
  font-size: 1.3rem;
  max-width: 22rem;
  max-width: 13rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.95em;
  margin-bottom: 1.35em;
}

.booking-box p {
  margin-top: 0.75rem;
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 0.25em;
}

#fast-track-box input[type="checkbox"].left-checkbox + label::before {
  margin-bottom: 0.1em;
  border-color: #ccc;
}

.player-count {
  margin: 2em;
  display: flex;
  justify-content: center;
  margin: auto;
}

.player-count-value {
  text-align: center;
  font-weight: bold;
  width: 3.5rem;
  margin: 0 2rem;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
  line-height: 2.1;
}

.player-count-change {
  margin-left: 0.25em;
  margin-right: 0.25em;
  border-radius: 1em;
  border-color: #dd1515;
  border-style: solid;
  border-width: 1px;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  width: 1em;
  height: 1em;
  cursor: pointer;
  font-weight: 900;
  transition: all 0.5s ease;
  color: #cfcfcf;
}

.player-count-change:hover {
  color: #ffffff;
  border-color: #dd3030;
}

.player-count-change.grey-out {
  border-color: #434343;
  background: transparent;
  color: #434343;
}

.booking-box .fully-flexible-wrapper {
  color: #ccc;
}

.player-count-right-inner {
  margin: 0.75rem 0;
  display: flex;
  justify-content: center;
}

.pay-button-box {
  margin: 1em;
  display: flex;
  justify-content: center;
}

.reserve-date-wrap {
  margin: 0 0 1rem 0;
  text-align: center;
}

#fast-select-date {
  display: none;
}

.fast-select-date-select.white-base {
  border-radius: 5px;
}

.fast-select-date,
.player-count,
.fast-total {
  display: flex;
  justify-content: space-between;
  /* margin-top: 1.5rem;
  margin-bottom: 2rem; */
}

.fast-select-date-right,
.player-count-right {
  max-width: 20em;
  flex-grow: 1;
}

.fast-select-date-select-top h5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.fast-select-date-select-top img {
  width: 2rem;
}

.fast-select-date-select {
  padding: 0.5rem 1rem;
}

.fast-select-date-select p {
  margin: 0.1rem 0 0 0;
}

.fully-flexible-wrapper {
  display: flex;
  max-width: 22rem;
  margin-left: auto;
  margin-right: auto;
}

.fully-flexible-wrapper img {
  width: 4rem;
  max-width: 24px;
  max-height: 24px;
  margin: 1rem;
  vertical-align: middle;
}

.fully-flexible {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

div.fully-flexible {
  font-size: 1rem;
}

section.main .text-cont p span.fully-flexible {
  text-transform: none;
  display: block;
}

.pay-button-box button {
  width: 21rem;
  margin-left: auto;
  margin-right: auto;
}

.redeem-voucher {
  display: flex;
  justify-content: center;
  margin-top: 1.5em;
}

.fast-track-voucher-code {
  width: 200px;
  margin: auto;
  display: block;
  margin-top: 1em;
}

@media screen and (max-width: 890px) {
  .booking-box.width-43 {
    margin: 1rem auto;
    padding: 2em;
  }
}

@media screen and (max-width: 755px) {
  .booking-box.width-43 {
    margin: 1rem 2rem;
  }
}

@media screen and (max-width: 600px) {
  .fast-book-head h2 {
    margin-bottom: 1rem;
  }

  .booking-box.width-43 {
    margin: 0 1rem 1rem 1rem;
    padding: 1em;
  }

  .booking-calendar {
    margin: 1em 0;
  }

  .fast-select-date-select {
    padding: 0.5rem;
  }

  .fast-select-date-label,
  .player-count-label {
    min-width: 5.5rem;
  }

  .booking-box h5 {
    margin-right: 0.5em;
  }

  .booking-box h4 {
    margin-top: 1em;
    margin-bottom: 0;
    justify-content: center;
    font-family: "RajdhaniRegular", "Arial", "Helvetica", "sans-serif";
  }

  .booking-box p {
    text-align: center;
  }

  .fast-select-date-select-top h5 {
    margin: 0;
    position: relative;
  }

  .fast-select-date-select-top img {
    position: absolute;
    right: 0;
    top: -0.15em;
    width: 1.25rem;
  }

  .fast-select-date-select.white-base p {
    text-align: left;
  }

  .fast-select-date-right > p {
    display: none;
  }

  h4.fast-total > span {
    margin-bottom: 0.5rem;
    display: inline-block;
  }

  h4.fast-total > span:first-child {
    margin-right: 4rem;
  }

  .pay-button-box {
    margin: 0 auto 0.5em;
  }

  .pay-button-box button,
  .booking-box .fully-flexible {
    width: 220px;
  }

  .booking-box .fully-flexible-wrapper {
    justify-content: center;
    color: #ccc;
  }

  .redeem-voucher {
    margin-top: 1.2em;
  }
}

@media screen and (max-width: 345px) {
  .fast-select-date-select p,
  .fast-select-date-select h5 {
    text-align: left;
    justify-content: left;
    margin-left: 0;
  }
}

@media screen and (max-width: 430px) {
  body h1:first-of-type:not(.no-glow) {
    margin-left: auto;
    margin-right: auto;
    font-size: 45px;
  }
  .player-count-value {
    margin: 0 auto;
  }
  .player-count-right-inner {
    max-width: 13rem;
    margin: 0.75rem auto;
  }
}

.spaces-payment p {
  font-size: 1.25rem;
  margin-top: 0;
}
.spaces-payment label {
  font-size: 1.25rem;
}

/* Post-fasttrack set-up  */

.my-game.title-background-wrapper {
  padding-bottom: 0;
}

.set-up-your-game-head {
  padding-bottom: 0;
}

.set-up-your-game-head > div {
  margin-bottom: 4.5rem;
}

.player-icon {
  height: 1em;
  margin-right: 0.5em;
  vertical-align: sub;
  margin-bottom: 0.1rem;
}

.set-up-your-game-head h3 {
  margin-bottom: 0;
}

.set-up-your-booking-head p {
  margin-top: 0;
}

.set-up-your-teams-head.red-border {
  padding: 0.5em;
}

.team-form-block form .button.pointer:nth-child(2n - 1) {
  /* margin-top: 2em; */
  margin-bottom: 1em;
}

@media (max-width: 600px) {
  .team-form-block form .button.pointer {
    /* flex: 0 0 220px; */
    text-align: left;
  }
}

@media (min-width: 530px) {
  .team-form-block form .button.pointer:nth-child(2n) {
    float: right;
  }
}

@media (max-width: 768px) {
  .set-up-your-teams-head,
  .set-up-your-booking-head,
  .create-edit .team-form-head-wrap {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 890px) {
  #createTeam .team-form-block.team-form,
  #createTeam .team-form-head-wrap {
    margin-left: auto;
    margin-right: auto;
  }
  .my-game.title-background-wrapper {
    height: 19rem;
  }
}

.team-form-block form .form-buttons.form-item.no-right-padding.no-left-padding {
  margin-top: 1.5em;
}

section.hearsay {
  padding-top: 3.75em;
}

.hearsay select {
  padding-left: 0.75rem;
  padding: 0.75em 1em 0.75em 0.75em;
}

.title-background-wrapper.how-did-you-hear-heading {
  height: auto;
}

.set-up-title p {
  margin-top: 0;
}

#howDidYouHearAdditional {
  padding: 1em;
  height: 3em;
  padding: 0 1em;
}

/* @-moz-document url-prefix() {
  .hearsay select {
    margin-left: -0.5rem;
  }
  
  #why_here_dto_howDidYouHearAdditional {
    height: 2em;
    width: calc(100% - 1.1rem);
    border-radius: 0.4em;
  }
} */

.booking-review-inner {
  padding: 2rem;
  width: 100%;
}

/* .submit-hearsay-wrap,  */
.hearsay.width-43 .booking-review-bottom.text-left {
  margin-left: 1rem;
  margin-right: 1rem;
}

/* .submit-hearsay-wrap,  */
#why_here_dto > div:last-of-type {
  display: flex;
  justify-content: center;
}

/* .submit-hearsay-wrap button.button.primary,  */
.hearsay .system-form [type="submit"].longer-text-button.button {
  font-size: 1.5rem;
  height: 60px;
  max-width: 100%;
  margin-top: 0.25em;
}

@media screen and (max-width: 600px) {
  .submit-hearsay-wrap .button,
  .longer-text-button.button,
  .system-form [type="submit"],
  .system-form [type="submit"].longer-text-button.button {
    width: 100%;
  }
  .submit-hearsay-wrap .button {
    padding-left: 0.75em;
    padding-right: 2em;
  }
  /* .submit-hearsay-wrap button.button.primary {
      font-size: 1.5rem;
  } */
}

/* 
input#redeem-checkbox[type="checkbox"] + label::before {
  content: "";
  width: 1em;
  height: 1em;
  display: inline-block;
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
  border-style: solid;
  border-color: #323232;
  border-width: 1px;
  vertical-align: middle;
  margin-left: 0.5em;

}

input#redeem-checkbox[type="checkbox"] + label::after {
  display: none;
} */

.on-no-honig {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

@media print {
  *,
  ::after,
  ::before {
    text-shadow: none;
    box-shadow: none;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  h2,
  p {
    orphans: 3;
    widows: 3;
  }

  h2 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body {
    min-width: 992px;
  }

  .container {
    min-width: 992px;
  }
}

.poster-play-video {
  cursor: pointer;
  width: 100%;
}

.loading-inline-content {
  text-transform: uppercase;
}

@keyframes blinkdot {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.loading-inline-content-dots span {
  animation-name: blinkdot;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  font-size: 1.5em;
}
.loading-inline-content-dots span:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-inline-content-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

/* Feedback form mobile height adjustment */

@media (max-width: 720px) {
  .feedback-form .title-background-wrapper {
    padding-bottom: 0;
    min-height: unset;
  }
  .feedback-form h1:first-of-type:not(.no-glow) {
    margin-bottom: 3.125rem;
    margin-bottom: 2.125rem;
  }
}

.no-padding-top {
  padding-top: 0 !important;
}

/* Team page */

.people-grid {
  display: flex;
  flex-wrap: wrap;
}

.people-grid .person {
  padding: 2em;
  width: calc(100% / 3);
}

@media (max-width: 600px) {
  .people-grid .person {
    width: calc(100%);
  }
}

.people-grid .person-image {
  width: 100%;
  max-width: 200px;
  display: block;
  margin: auto;
}

.people-grid .person-name {
  text-transform: uppercase;
  display: block;
  font-size: 1.1em;
  line-height: 1;
  padding-top: 0.7em;
  padding-bottom: 0.3em;
}

.people-grid .person-organisation {
  font-weight: normal;
  display: block;
  font-size: 1.1em;
  opacity: 0.5;
  line-height: 1.2;
  padding: 0.2 0 0.3em 0;
}

.people-grid .person-role {
  line-height: 1.2;
  max-width: 250px;
  display: block;
  text-align: center;
  margin: auto;
}

/* Book with confidence and experience guidance blocks */

.iconographic-guidance,
.book-with-confidence {
  width: 60rem;
  max-width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.experience-guidance {
  margin-top: 2rem;
}

.iconographic-guidance-block,
.book-with-confidence-block {
  margin-top: 1em;
  padding: 0.5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.book-with-confidence-block {
  flex: 1 1;
}

.iconographic-guidance-icon-wrapper,
.book-with-confidence-icon-wrapper {
  flex: 0 0 6rem;
  display: flex;
  align-items: flex-end;
}

.book-with-confidence-icon-wrapper {
  flex: 0 0 10rem;
}

.iconographic-guidance-icon,
.book-with-confidence-icon {
  vertical-align: bottom;
}

.iconographic-guidance-icon {
  width: 5.5rem;
  height: 5.5rem;
}

.book-with-confidence-icon {
  width: 10rem;
  height: 10rem;
}

.iconographic-guidance-icon--landscape,
.book-with-confidence-icon--landscape {
  width: 6.5rem;
}

.book-with-confidence-icon--landscape {
  width: 12rem;
  height: auto;
}

.iconographic-guidance-text,
.book-with-confidence-text {
  display: flex;
  width: 100%;
  text-align: center;
  padding: 0.8em;
}

.iconographic-guidance p,
.book-with-confidence p {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 701px) {
  .iconographic-guidance-block {
    flex: 1 1;
  }
}

@media (max-width: 890px) {
  .iconographic-guidance-text br {
    display: none;
  }
}

@media (max-width: 700px) {
  .book-with-confidence,
  .iconographic-guidance {
    flex-wrap: wrap;
    width: 29rem;
  }

  .iconographic-guidance {
    align-items: center;
  }

  .experience-guidance {
    margin-top: 3rem;
  }

  .book-with-confidence {
    flex-direction: column;
  }

  .iconographic-guidance p {
    margin: 0 auto;
  }

  .book-with-confidence p {
    margin: 0;
    padding: 0;
    text-align: left;
  }

  .experience-guidance .iconographic-guidance-block {
    flex: 0 0 50%;
    max-width: 10rem;
    margin-top: 0;
  }

  .book-with-confidence-block {
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    align-content: center;
    justify-content: flex-start;
  }

  .experience-guidance-block--locales {
    order: 1;
  }

  .iconographic-guidance-icon-wrapper {
    flex: 0 0 5rem;
  }

  .book-with-confidence-icon-wrapper {
    flex: 0 0 6rem;
  }

  .iconographic-guidance-icon,
  .book-with-confidence-icon {
    width: 5rem;
    height: 5rem;
  }

  .book-with-confidence-icon--landscape {
    width: 6rem;
  }

  .book-with-confidence-text {
    width: 70%;
    padding-left: 0.5rem;
    align-self: center;
  }
}
