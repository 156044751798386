@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap');

/* Fonts */

@font-face {
  font-family: "GoboldExtra1";
  src: url("../fonts/GoBoldExtra1/GoboldExtra1.eot");
  src: url("../fonts/GoBoldExtra1/GoboldExtra1.eot?#iefix") format("embedded-opentype"), url("../fonts/GoBoldExtra1/GoboldExtra1.woff2") format("woff2"), url("../fonts/GoBoldExtra1/GoboldExtra1.woff") format("woff"), url("../fonts/GoBoldExtra1/GoboldExtra1.ttf") format("truetype"), url("../fonts/GoBoldExtra1/GoboldExtra1.svg#GoboldExtra1") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "RajdhaniRegular";
  src: url("../fonts/Rajdhani/Rajdhani-Regular.eot");
  src: url("../fonts/Rajdhani/Rajdhani-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Rajdhani/Rajdhani-Regular.woff2") format("woff2"), url("../fonts/Rajdhani/Rajdhani-Regular.woff") format("woff"), url("../fonts/Rajdhani/Rajdhani-Regular.ttf") format("truetype"), url("../fonts/Rajdhani/Rajdhani-Regular.svg#Rajdhani-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RajdhaniSemiBold";
  src: url("../fonts/Rajdhani/Rajdhani-SemiBold.eot");
  src: url("../fonts/Rajdhani/Rajdhani-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Rajdhani/Rajdhani-SemiBold.woff2") format("woff2"), url("../fonts/Rajdhani/Rajdhani-SemiBold.woff") format("woff"), url("../fonts/Rajdhani/Rajdhani-SemiBold.ttf") format("truetype"), url("../fonts/Rajdhani/Rajdhani-SemiBold.svg#Rajdhani-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Intro;
  src: url(../fonts/intro/intro-regular.otf);
}

@font-face {
  font-family: IntroBold;
  src: url(../fonts/intro/intro-bold.otf);
}

@font-face {
  font-family: Solomon;
  src: url(../fonts/solomon/solomon-normal.otf);
}
@font-face {
  font-family: SolomonBold;
  src: url(../fonts/solomon/solomon-bold.otf);
}
@font-face {
  font-family: SolomonBlack;
  src: url(../fonts/solomon/solomon-black.otf);
}
@font-face {
  font-family: Arquitecta;
  src: url(../fonts/arquitecta/arquitecta.otf);
}
@font-face {
  font-family: ArquitectaBold;
  src: url(../fonts/arquitecta/ArquitectaBold.otf);
}
@font-face {
  font-family: ArquitectaBlack;
  src: url(../fonts/arquitecta/ArquitectaBlack.otf);
}

.gamesmaster {
  font-family: "GoboldExtra1", "Arial", "Helvetica", "sans-serif";
  line-height: 1.4;
  letter-spacing: 0.2em;
}

@media(max-width:500px) {
  .gamesmaster {
    letter-spacing: 0.1em;
  }
}

.bodyReg {
  font-family: "RajdhaniRegular", "Arial", "Helvetica", "sans-serif";
}

.bodyBold,
b,
strong {
  font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
}

.epicvoice {
  font-family: "introBold", "Arial", "Helvetica", "sans-serif";
  line-height: 1.1;
  letter-spacing: 0.025em;
}

.epicvoiceReg {
  font-family: "intro", "Arial", "Helvetica", "sans-serif";
  line-height: 1.1;
  letter-spacing: 0.025em;
}

h1, h2, h3, h4, h5 {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
}

h1.gamesmaster {
  font-size: 50px;
}

h2.gamesmaster, h2 {
  font-size: 40px;
}

h3.gamesmaster {
  font-size: 32px;
}

h4.gamesmaster {
  font-size: 28px;
}

h5.gamesmaster {
  font-size: 22px;
}

h1.epicvoice {
  font-size: 40px;
}

h2.epicvoice {
  font-size: 32px;
}

h3.epicvoice {
  font-size: 28px;
}

h4.epicvoice {
  font-size: 24px;
}

h5.epicvoice {
  font-size: 20px;
}

p, h6, .main-ul, .main-ol, .questions-block {
  font-family: "RajdhaniRegular", "Arial", "Helvetica", "sans-serif";
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.01em;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

p b, h6 {
  font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
}

em, i {
  font-style: italic;
}


.red {
  color: #dd1515;
}

.gray {
  color: #666;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

body {
  font-family: "RajdhaniRegular", "Arial", "Helvetica", "sans-serif";
  font-size: 16px;
  color: rgb(233, 233, 233);
}

.caps {
  text-transform: uppercase;
}

.uppercase {
  text-transform: uppercase;
}

.titlecase {
  text-transform: capitalize;
}

.font18 {
  font-size: 1rem;
}

.font-21 {
  font-size: 21px;
}

.font22 {
  font-size: 1.2rem;
}

.font25 {
  font-size: 1.39rem;
}

.font32 {
  font-size: 1.778rem;
}

.font36 {
  font-size: 2rem;
}

@media screen and (max-width: 890px) {
  .font18 {
    font-size: 0.9rem;
  }

  .font22 {
    font-size: 1.05rem;
  }

  .font25 {
    font-size: 1.25rem;
  }

  .font32 {
    font-size: 1.5rem;
  }

  .font36 {
    font-size: 1.75rem;
  }
}

.large {
  font-size: larger;
}

.spacing-normal,
section.main.hunt-styles .spacing-normal {
  letter-spacing: normal;
}

.small-caps {
  font-variant: all-small-caps;
}

