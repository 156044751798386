/* Actions Table */

.txt-actions {
  margin-top: 5em;
}

.actions-table {
  opacity: 0.95;
}

@media screen and (max-width: 890px) {
  .actions-table-wrapper {
    margin: 1em;
  }
}

.actions-table,
.leaderboard-wrapper {
  max-width: 1000px;
  flex-direction: column;
}

.actions-table,
.actions-table > div {
  display: flex;
  background-color: rgba(34, 32, 32, 0.75);
}

.actions-table .col1,
.actions-table .col3 {
  width: 25%;
}

.actions-table .col2 {
  width: 50%;
}

.actions-table > div:not(:last-child):not(:first-child) > div {
  border-bottom: 2px dotted rgb(80, 80, 80);
}

.actions-table * {
  vertical-align: middle;
}

.actions-table > div:not(:first-child) > div {
  padding: 2em;
}

.actions-table > div:first-child > div {
  text-align: left;
  font-size: 22px;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions-table > div:first-child span {
  margin: 1em;
}

.actions-table img {
  max-width: 1.5em;
  float: right;
}

.table-text {
  font-size: 0.9em;
}

.actions-table > div > div:nth-child(2n) {
  background-color: #323232;
  text-align: left;
}

.col1,
.col2,
.col3 {
  text-align: center;
}

.actions-table > div:first-child {
  background-color: rgb(68, 32, 37);
}

.actions-table > div:first-child > div:nth-child(2n) {
  background-color: rgb(68, 45, 48);
}

.well-send::before {
  content: "We'll send...";
}

.table-header-row {
  height: 4em;
}

.table-header-row span {
  font-size: 0.8em;
}

/* Leaderboard Section */

section.leaderboard > div > h1 > br,
section.leaderboard > section > div.leaderboard-table-wrapper-1 > h5 > br {
  display: none;
}

.leaderboard-button-wrapper {
  margin-top: 0.5em;
}

.leaderboard-button-wrapper .button {
  float: right;
}

/* Leaderboard Section > Table 1 */

.leaderboards-page-leaderboard.first-leaderboard {
  padding-top: 6em;
}

.leaderboard-1-background-image img {
  max-width: 80%;
  width: 40%;
  position: absolute;
  z-index: -1;
}

.leaderboard-table-wrapper-1,
.leaderboard-table-wrapper-2 {
  padding: 1em 1em 2em;
}

.leaderboard-header-background-image {
  background-image: url("../images/home/cta3-desktop.jpg");
  padding-top: 0;
  height: 35em;
  box-shadow: 2em -1em 6em 6em #101316 inset;
}

.leaderboard-table > div > div {
  padding: 1em;
}

.actions-table.leaderboard-table > div:not(:first-child) > div {
  padding: 1em;
}

.leaderboard-table .table-header-row p {
  font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
}

.leaderboard-button {
  float: right;
  margin-top: 1em;
  margin-left: 1em;
}

/* Leaderboard Section > Table 2 */

.leaderboard-2-background-image {
  position: relative;
  left: 45%;
  z-index: -1;
}

.leaderboard-2-background-image img {
  max-width: 80%;
  position: absolute;
}

.leaderboards-page-leaderboard:nth-child(odd) h4 {
  text-align: left;
}

.leaderboards-page-leaderboard:nth-child(even) h4 {
  text-align: right;
}

.leaderboards-page-leaderboard:nth-child(odd)
  .leaderboard-1-background-image
  img {
  right: 0;
}

.leaderboards-page-leaderboard:nth-child(even)
  .leaderboard-1-background-image
  img {
  left: 0;
}

@media screen and (min-width: 890px) {
  /* .leaderboards-page-leaderboard>.leaderboard-hunt-title.h4 {
        text-align: center;
    } */
  .actions-table.leaderboard-table .col1,
  .actions-table.leaderboard-table .col2,
  .actions-table.leaderboard-table .col3,
  .actions-table.leaderboard-table .col4,
  .actions-table.leaderboard-table .col5,
  .actions-table.leaderboard-table .col6 {
    width: calc((100% - 3rem) / 6);
    text-align: center;
  }
  .actions-table p {
    width: 100%;
    line-height: 1;
    margin: 0.75em 0;
  }
  .actions-table.leaderboard-table .col0 {
    text-align: center;
    width: 3rem;
  }
}

@media screen and (max-width: 890px) {
  /* Show hide toggle on mobile leaderboard */

  .col0 {
    cursor: pointer;
  }

  .col0 span:after {
    float: right;
    width: 1.5em;
    height: 1.5em;
    content: url("../images/icons/plus.svg");
  }
  .col0 ~ .col-mobile-expand {
    display: none !important;
  }
  .col0.open span:after {
    float: right;
    width: 1.5em;
    height: 1.5em;
    content: url("../images/icons/minus.svg");
  }
  .col0.open ~ .col-mobile-expand {
    display: block !important;
  }
  .leaderboard-wrapper .background-image {
    top: 90px;
    position: relative;
  }
  .leaderboard-wrapper .background-image img {
    width: 100%;
    max-width: unset;
  }
  .leaderboard-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 2em;
    padding-right: 2em;
  }
  .leaderboard-button-wrapper .button {
    float: none;
    width: 300px;
  }
  /* MQ - Actions and Leaderboard Tables */
  .actions-table {
    margin-left: 1em;
    margin-right: 1em;
  }
  .actions-table,
  .actions-table > div {
    display: block;
  }
  .actions-table .table-header-row {
    display: none;
  }
  .actions-table .col1,
  .actions-table .col2,
  .actions-table .col3 {
    display: block;
    width: 100%;
  }
  .actions-table > div:not(:last-child):not(:first-child) > div {
    border-bottom: none;
  }
  .table-text {
    display: block;
    line-height: 1.5em;
    padding-top: 0.5em;
  }
  .actions-table > div > div:nth-child(2n) {
    background-color: unset;
  }
  div:not(.leaderboard-table) > div.row:not(.last) {
    border-bottom: 2px dotted rgb(80, 80, 80);
  }
  .row2,
  .row4,
  .row6 {
    background-color: rgba(50, 50, 50, 0.7);
  }
  .col1,
  .col2,
  .col3 {
    text-align: left;
  }
  .row > div {
    position: relative;
  }
  .row > div .table-text {
    font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
  }
  /* Leaderboard table */
  .table-text {
    opacity: 1;
    color: white;
  }
  .leaderboard-table .row > div:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
  }
  .leaderboard-table .row > div .table-text {
    font-family: "RajdhaniRegular", "Arial", "Helvetica", "sans-serif";
    padding-top: 0;
  }
  .leaderboard-table .row > div:not(:first-child) {
    text-align: center;
    padding: 1em;
  }
  .leaderboard-table > .row > .col0 {
    background-color: rgba(68, 32, 37, 0.7);
    margin-bottom: 2em;
    padding: 1em 1.25em;
    text-align: left;
    font-weight: bolder;
    opacity: 1;
  }
  .leaderboard-table > .row {
    background-color: rgba(50, 50, 50, 0.7);
    padding-bottom: 1em;
  }
  .leaderboard-table > .row:nth-child(2n) {
    background-color: rgba(34, 32, 32, 0.7);
  }
  .leaderboard-table .row > div:before {
    content: attr(data-label);
  }

  /* Actions table */
  .title-background-wrapper ~ .main.step .txt-actions {
    margin-top: -8em;
  }
  .actions-table:not(.leaderboard-table),
  .actions-table:not(.leaderboard-table) > div {
    width: 100%;
  }
  .actions-table:not(.leaderboard-table) .row > div .table-text {
    padding-top: 4em;
  }
  .actions-table:not(.leaderboard-table) .row > div:before {
    width: 2.5em;
    display: block;
    position: absolute;
    top: 10px;
  }
  .row > div:nth-child(1):after,
  .row > div:nth-child(2):after,
  .row > div:nth-child(3):after {
    color: #dd1515;
    display: block;
    position: absolute;
    top: 4.5em;
  }
  .actions-table:not(.leaderboard-table) .row > div:nth-child(1):before {
    content: url("../images/icons/Send_Red.svg");
  }
  .actions-table:not(.leaderboard-table) .row > div:nth-child(1):after {
    content: "If you send...";
  }
  .actions-table:not(.leaderboard-table) .row > div:nth-child(2):before {
    content: url("../images/icons/Recieve_Red.svg");
  }
  .actions-table:not(.leaderboard-table) .row > div:nth-child(2):after {
    content: "We will...";
  }
  .actions-table:not(.leaderboard-table) .row > div:nth-child(3):before {
    content: url("../images/icons/Clock_Red.svg");
  }
  .actions-table:not(.leaderboard-table) .row > div:nth-child(3):after {
    content: "Time penalty :";
  }
}
