.button,
.system-form [type="submit"] {
  position: relative;
  color: white !important;
  background: rgba(221, 21, 21, 0.8);
  text-decoration: none;
  font-family: "RajdhaniRegular", sans-serif;
  font-weight: bold;
  font-size: 18px;
  display: inline-block;
  line-height: 1.2;
  letter-spacing: 0.3px;
  z-index: 1;
  transition: linear 0.1s;
  min-width: 4.9em;
  cursor: pointer;
}

.button:before,
.system-form [type="submit"]:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: #dd1515;
  opacity: 0;
  z-index: 0;
  transition: linear 0.3s;
  pointer-events: none;
}

.button:after,
.system-form [type="submit"]:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #dd1515;
  opacity: 0;
  z-index: 0;
  transition: linear 0.3s;
  pointer-events: none;
}

.button:hover,
.system-form [type="submit"]:hover {
  transition: linear 0.3s;
  background-color: rgba(221, 21, 21, 0.3);
}

.button:hover:before,
.system-form [type="submit"]:hover:before {
  opacity: 0.01;
  top: -5px;
  right: -5px;
  transition: linear 0.2s;
  animation: floatup 1.8s linear infinite;
}

.button:hover:after,
.system-form [type="submit"]:hover:after {
  opacity: 0.3;
  bottom: -5px;
  left: -5px;
  transition: linear 0.2s;
  animation: floatdown 1s linear infinite;
}

.button,
.system-form [type="submit"] {
  padding-left: 1em;
  padding-right: 4em;
  height: 45px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.button.pointer,
.system-form [type="submit"],
#console [type="submit"] {
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url("../images/icons/buttonArrow.svg");
}

.button.plus {
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url("../images/icons/buttonArrow.svg");
}

.button.validate, .button.refresh {
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url("../images/icons/buttonValidate.svg");
}

.button.secondary {
  filter: grayscale(80);
  margin-right: 1em;
}

@keyframes floatup {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(4px, -5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes floatdown {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-5px, 3px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@media (max-width: 600px) {
  .button:not(#learnMoreButton),
  .system-form [type="submit"] {
    width: 220px;
    margin: 0.25em 0 !important;
  }
  .longer-text-button.button, .longer-text-button.system-form [type="submit"] {
    width: 100%;
  }
}


/* Reset default button */

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

.system-form [type="submit"] {
  margin-top: 1em;
  max-width: 10em;
  border-radius: 0;
  margin: auto;
  display: block;
}

button.button {
  line-height: 0;
}

.card-button-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-button-wrapper-gift {

  justify-content: space-evenly;

}

.card-button-wrapper-gift .button {
  line-height: 1;
}


/* FLOATING BUTTONS RETURN! */

@media screen and (min-width: 890px) {
  .floating.book-btn {
    position: fixed;
    z-index: 99990;
    top: 130px;
    left: 80px;
    text-align: center;
  }
  body[data-sticky] .floating.book-btn {
    top: 90px;
    left: 80px;
  }
}

@media screen and (max-width: 890px) {
  .floating.book-btn {
    position: fixed;
    z-index: 99990;
    top: 62px;
    left: 17px;
  }
}

.floating.book-btn a {
  line-height: 1;
}

#payment-request-button--card {
  justify-content: center;
  padding: 0 1em;
}
